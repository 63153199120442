
import * as React from 'react'
import Link from 'next/link'
import { useQueryParam } from '../../../hooks/useQueryParam'
import classNames from 'classnames'

export function GetAQuoteButton(props: { buttonText: string; buttonProps?: any; textColor?: string }) {
  const query = useQueryParam()
  return (
    <div className="text-center">
      <Link href={{ pathname: `/get-quote/`, query }}>
        <a>
          <Button
            className={'w-full block sm:w-auto sm:inline'}
            style={{
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 30,
              paddingRight: 30,
              color: props.textColor || 'white',
            }}
            {...props.buttonProps}
          >
            {props.buttonText}
          </Button>
        </a>
      </Link>
    </div>
  )
}

export const Button: React.FC<
  { disabled?: boolean } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ children, className, disabled = false, ...rest }) => {
  const disabledClassName = 'disabled:bg-green-300 disabled:cursor-not-allowed'
  const baseClassName =
    'inline-block bg-lime hover:bg-green-600 justify-center text-white py-2 text-lg font-semibold px-5 rounded'
  return (
    <button disabled={disabled} className={classNames(baseClassName, disabledClassName, className)} {...rest}>
      {children}
    </button>
  )
}
