import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import affirm from '../../../../public/img/affirm.svg'
import klarna from '../../../../public/img/klarna.svg'
import { Container } from '../../Container'
import { twDefaultInitialWizardFormValues } from '../../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormMultiStep'
import { StarsAndRatings } from '../../SEMPage/StarsAndRatings'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { getTitleFromUTMS } from '../../SEMPage/getTitleFromUTMS'
import { propOr } from 'ramda'
import * as changeCase from 'capital-case'
import { CityPhoneNumberData } from '../../SEMPage/CityPhoneNumberData'
import { formatPhoneNumber } from '../../utils/string-utils'
import { setContentWithFallbacks } from '../templates/SEMLayoutTemplate'
import { InlineLinkOutForm } from '../../Forms/InlineLinkoutForm'

export const KlarnaAffirmHeaderInline: React.FC<{
  title: string
  subtitle?: string
  rating: string
  reviewCount: number
  subheading: string
  image?: string
  showImage?: boolean
  showGradient?: boolean
  headerTitle?: string
  eventVariationLabel?: string
  showDynamicTitle?: boolean
  ratingsText?: string
  isLinkForm?: boolean
  linkTarget?: string
  buttonText?: string
}> = ({
  title,
  rating,
  reviewCount,
  headerTitle,
  showDynamicTitle = false,
  isLinkForm,
  buttonText,
  linkTarget,
}) => {
  const query = useQueryParam()
  // TODO refactor the data here
  const city: string = propOr('', 'city', query)
  const type: string = propOr('', 'type', query)
  const make: string = propOr('', 'make', query)
  let content: string = propOr('', 'utm_content', query)
  content = setContentWithFallbacks(type, content)
  const formalCity = city ? changeCase.capitalCase(city) : ''
  const phoneNumber =
    city && CityPhoneNumberData[city] ? CityPhoneNumberData[city!].phoneNumber : CityPhoneNumberData.default.phoneNumber
  const phoneNumberTxt = formatPhoneNumber(phoneNumber)

  const dynamicTitle = getTitleFromUTMS({
    formalCity,
    phoneNumber,
    phoneNumberTxt,
    type,
    content,
    make,
    rating,
    reviewCount,
  })
  return (
    <>
      <section className="flex relativepb-0 sm:pb-4 pt-0 sm:pt-4">
        <Container className="flex text-center z-10 w-full">
          <div className="mt-4 mb-6 flex self-end flex-col w-full">
            <div>
              <div className="text-center">
                {showDynamicTitle ? (
                  <h1 className="mb-0 mt-1 text-3xl lg:text-4xl leading-none title-wizard px-0 sm:px-8 md:px-16">
                    {dynamicTitle}
                  </h1>
                ) : (
                  <h1 className="mb-1 sm:mb-0 mt-1 text-2xl sm:text-3xl md:text-3xl lg:text-4xl leading-none title-wizard px-0 sm:px-8 md:px-16">
                    {title}
                  </h1>
                )}

                {headerTitle && (
                  <p
                    className={
                      'py-2 text-gray-500 text-sm sm:text-base md:text-xl mb-2 pb-1 md:mb-2 mt-0 px-8 sm:px-4 leading-tight sub-title-wizard'
                    }
                  >
                    {headerTitle}
                  </p>
                )}
              </div>
              <div className="bg-gray-100 py-4 border-gray-200 border">
                <InlineLinkOutForm
                  initialValues={{
                    ...twDefaultInitialWizardFormValues,
                    'form-name': 'getQuoteScheduleService',
                  }}
                  subtitle={''}
                  isLinkForm={isLinkForm}
                  buttonText={buttonText}
                  linkTarget={linkTarget}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 pt-4">
              <div className="bg-gray-100 px-1 py-2 border border-gray-200 md:col-start-2">
                <div className="pb-2">
                  <StarsAndRatings />
                </div>
                <p className="leading-4">{`Over ${reviewCount} 5 star reviews`}</p>
              </div>
              <Link href={{ pathname: '/financing', query }}>
                <a>
                  <div className="bg-gray-100 p-2 border-gray-200 cursor-pointer">
                    <div className="grid grid-cols-2 gap-1 mb-2">
                      <Image src={affirm} width={50} height={20} alt="Affirm Logo" />
                      <Image src={klarna} width={50} alt="Klarna Logo" />
                    </div>
                    <p className="leading-4">Interest free payment plans</p>
                  </div>
                </a>
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
