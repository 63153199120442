import React from 'react'
import { withChildren } from '@builder.io/react'
import { Container } from '../../Container'
export const OurTechnicians = (props) => {
  return (
    <section>
      <Container className="text-center">
        <div>
          <h2 className="title text-4xl mb-3">{props.title}</h2>
          <div
            className="text-xl"
            dangerouslySetInnerHTML={{
              __html: props.subtitle,
            }}
          />
          <div>{props.children}</div>
        </div>
      </Container>
    </section>
  )
}

export const BuilderOurTechniciansWithChildren = withChildren(OurTechnicians)
