import React from 'react'
import { Builder, BuilderComponent } from '@builder.io/react'
import Head from 'next/head'
import Layout from '../components/Layout'
import { Container } from '../components/Container'
import { AboutPageTitleSection } from '../components/AboutPageTitleSection'

const NotFoundPage = () => {
  if (Builder.isPreviewing || Builder.isEditing) {
    // Protect from 404s in preview or edit mode on live URLs
    return <BuilderComponent model="page" />
  }
  return (
    <Layout title={`Whoops...`}>
      <Head>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <AboutPageTitleSection title={'Oops... It looks like you made a wrong turn...'} />
      <section className="text-center">
        <Container>
          <h3>Need Immediate Help?</h3>
          <p className="description">
            We strive to provide you with quality, hassle-free brake repair services and stand behind our work. We’re
            preparing your repair quote, but if you’d like immediate assistance or already know you’d like to set up
            service, don’t hesitate to call us.
          </p>
          <p className="description">Call now to speak with our brake team.</p>
          <a href="tel:8558005629" style={{ fontSize: 30 }}>
            (855) 800-5629
          </a>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage
