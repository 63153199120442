import React from 'react'
import bgImage from '../../../../public/img/nubrakes-brake-repair-header.jpeg'
import { BgImage } from '../../BgImage'

export const ContentHeader: React.FC<{
  title: string
  description?: string
  bgImageOverride?: string
}> = ({ title, description, bgImageOverride }) => {
  return (
    <section className="py-0 content-header">
      <BgImage src={bgImageOverride ?? bgImage}>
        <div className={'text-center'}>
          <h1 className="title text-white px-4 sm:px-0">{title}</h1>
          <p className={`text-white description`}>{description}</p>
        </div>
      </BgImage>
    </section>
  )
}
