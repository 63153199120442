// Additional Registrations for other Code Components by Category
import { Builder, withChildren } from '@builder.io/react'
import SEMPageVariation from '../SEMPage/SEMVariation'
// LAYOUTS FOR PAGE TYPES THAT ENCAPSULATE DATA AND PASS INTO COMPONENTS
import './registration/LayoutRegistration'
// HERO HEADERS
import './registration/HeroRegistration'
// FORMS
import './registration/FormRegistration'
// GRID DISPLAY COMPONENTS
import './registration/GridRegistration'
// GENERAL GLOBAL SITE COMPONENTS
import './registration/ComponentRegistration'
import { CarsRollWhite } from '../LandingPage/CarsRollWhite'
import { HappinessGuaranteeSection } from '../HappinessGuaranteeSection'
import { BrakeRepairNearYouWithCities } from '../LandingPage/BrakeRepairNearYouWithCities'
import { HomePageThirdSection } from '../LandingPage/HomePageThirdSection'
import { FeatureBySection } from '../FeatureBySection'
import { GraySection } from '../GraySection'
import { ServicesHowItWorksSection } from '../SEMPage/ServicesHowItWorksSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
const DownChevron = () => <FontAwesomeIcon icon={faChevronDown} size={'3x'} />

Builder.registerComponent(DownChevron, {
  name: 'Down Chevron Icon',
})

// TODO DEPRECATE THIS LATER ON INLINE HERO LAUNCH
const BuilderSEMPage = (props) => <SEMPageVariation {...props} />
Builder.registerComponent(BuilderSEMPage, {
  name: 'SEM Page Variation',
})

Builder.registerComponent(HomePageThirdSection, {
  name: 'Home Page Third Section',
})
Builder.registerComponent(FeatureBySection, {
  name: 'Feature By Section',
})
const GraySectionWithChildren = withChildren(GraySection)

Builder.registerComponent(GraySectionWithChildren, {
  name: 'Gray Section',
})

export const BuilderServicesHowItWorksSection = (props) => (
  <ServicesHowItWorksSection
    title={props.title}
    description={props.description}
    steps={[...props.steps]}
    phoneNumber={props.phoneNumber}
    buttonText={props.buttonText}
  />
)

Builder.registerComponent(BuilderServicesHowItWorksSection, {
  name: 'How It Works Section',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'NuBrakes Mobile Repair' },
    { name: 'description', type: 'string', defaultValue: 'Mobile Auto Repair Built for You' },
    { name: 'phoneNumber', type: 'string' },
    { name: 'buttonText', type: 'string', defaultValue: 'Get A Quote' },
    { name: 'buttonColor', type: 'color', defaultValue: '#ffc107' },
    {
      name: 'steps',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string', defaultValue: 'Step 1' },
        { name: 'description', type: 'string', defaultValue: 'Step 1 description' },
        {
          name: 'stepImage',
          type: 'string',
          enum: ['faMoneyCheckEditAlt', 'faCalendarCheck', 'faCarMechanic'],
          defaultValue: 'faMoneyCheckEditAlt',
        },
      ],
    },
  ],
})

const BuilderCarsRoll = ({
  builderState: {
    state: { carContent },
  },
}) => {
  return <CarsRollWhite carContent={carContent} />
}

Builder.registerComponent(BuilderCarsRoll, {
  name: 'Cars Roll',
})

const BuilderHappinessGuaranteeSection = ({ title, description }) => {
  return <HappinessGuaranteeSection title={title} description={description} />
}

Builder.registerComponent(BuilderHappinessGuaranteeSection, {
  name: 'Happiness Guarantee Section',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'The NuBrakes 5-Star Guarantee' },
    {
      name: 'description',
      type: 'string',
      defaultValue:
        'We want to make the brake repair process simple and frustration-free. If you’re not happy with your repair or replacement, we’ll do everything we can to fix it. Call or message us any day, any time, and we’ll do our best to fix whatever issue you’re having.',
    },
  ],
})

const BuilderBrakeRepairNearYouWithCities = ({
  title,
  builderState: {
    state: { cities },
  },
}) => {
  return <BrakeRepairNearYouWithCities title={title} cities={cities} />
}
Builder.registerComponent(BuilderBrakeRepairNearYouWithCities, {
  name: 'Near You Cities',
  inputs: [{ name: 'title', type: 'string', defaultValue: 'Schedule your mobile brake repair in minutes.' }],
})
