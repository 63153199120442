import * as React from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { CustomTextField } from '../Fields/CustomTextField'
import { useRouter } from 'next/router'
import { catchAll } from '../../util/quote-form-utils'
import { Button } from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { newBackendClient } from '../clients/new-backend.client'
import { Mutation, MutationFleetContactUsArgs } from '../../generated/nest-graphql'
import gql from 'graphql-tag'
import { CustomSelectField } from '../Fields/CustomSelectField'

export type FleetContactUsFormValues = {
  firstName: string
  lastName: string
  businessName: string
  numberOfVehicles: string
  email: string
  phoneNumber: string
  'form-name': string
  message: string
}

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  businessName: Yup.string().required('Required'),
  numberOfVehicles: Yup.string().required('Required'),
  email: Yup.string().email('Email address is not valid').required('Required'),
  // https://stackoverflow.com/a/16702965
  phoneNumber: Yup.string()
    .matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      'Phone number is not valid'
    )
    .required('Required'),
  message: Yup.string().required('Required'),
})

async function submitToBackend(values: FleetContactUsFormValues) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { 'form-name': formName, ...rest } = values
  await newBackendClient.mutate<Mutation, MutationFleetContactUsArgs>({
    variables: {
      contactUsInput: rest,
    },
    mutation: gql`
      mutation fleetContactUs($contactUsInput: ContactUsInput!) {
        fleetContactUs(contactUsInput: $contactUsInput)
      }
    `,
  })
}

export const FleetContactUsForm: React.FC = () => {
  const router = useRouter()
  return (
    <Formik<FleetContactUsFormValues>
      initialValues={{
        'form-name': 'contact',
        email: '',
        phoneNumber: '',
        businessName: '',
        numberOfVehicles: '',
        lastName: '',
        firstName: '',
        message: '',
      }}
      validationSchema={formSchema}
      onSubmit={async (values) => {
        await Promise.all([submitToBackend(values), catchAll(values)])
        await router.push('/fleet-success/').then(() => window.scrollTo(0, 0))
      }}
    >
      {({ isSubmitting }) => (
        <Form name={'contactForm'}>
          <Field type="hidden" name="form-name" value={'contactForm'} />
          <div className="mb-4">
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <Field name={'firstName'} component={CustomTextField} required={true} />
              </div>
              <div>
                <Field name={'lastName'} component={CustomTextField} required={true} />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <Field name={'businessName'} component={CustomTextField} required={true} />
              </div>
              <div>
                <Field
                  name={'numberOfVehicles'}
                  component={CustomSelectField}
                  options={[
                    '5 - 25',
                    '26 - 50',
                    '51 - 100',
                    '101 - 200',
                    '201 - 500',
                    '501 - 1,000',
                    '1,001 - 5,000',
                    '5,001+',
                  ]}
                  required={true}
                  errorTextStyle={{ color: 'red' }}
                />
              </div>
            </div>
            <div>
              <Field name={'email'} type={'email'} component={CustomTextField} required={true} />
            </div>
            <div>
              <Field name={'phoneNumber'} component={CustomTextField} required={true} />
            </div>
            <div>
              <Field name={'message'} as={'textarea'} component={CustomTextField} required={true} />
            </div>
          </div>
          <Button disabled={isSubmitting} type={'submit'}>
            {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : `Submit`}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
