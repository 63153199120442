import React from 'react'
import { fad, IconName } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

// Solid Style - fas
// Regular Style - far
// Light Style - fal
// Duotone Style - fad

export const faIconNameList = Object.keys(fad).map((key) => {
  return fad[key].iconName
})

export const FAIcon: React.FC<{ icon?: IconName; iconColor?: string; iconSize?: SizeProp }> = (props) => {
  const faIcons = Object.keys(fad).map((key) => {
    return fad[key]
  })

  const icon = faIcons.filter((icon) => icon.iconName === props.icon)

  return <FontAwesomeIcon style={{ color: props.iconColor }} icon={icon[0]} size={props.iconSize} />
}
