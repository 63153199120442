import * as React from 'react'
import { formatPhoneNumber } from '../../utils/string-utils'
import { LandingPageButton } from './Button'
import { useRouter } from 'next/router'
import { propOr } from 'ramda'
import { CityPhoneNumberData } from '../../SEMPage/CityPhoneNumberData'

type Props = {
  title: string
  description: string
  steps?: { title: string; description: string; image: string }[]
  buttonText?: string
  phoneNumber?: string
  buttonColor?: string
  buttonTextColor?: string
  children?: any
}

export const IconGridSection: React.FC<Props> = (props) => {
  const { title, description, children, buttonText, buttonColor, buttonTextColor } = props
  const router = useRouter()
  const { query } = router
  const city: string = propOr('', 'city', query)
  const dynamicPhoneNumber =
    city && CityPhoneNumberData[city] ? CityPhoneNumberData[city!].phoneNumber : props.phoneNumber
  const phoneNumberTxt = formatPhoneNumber(dynamicPhoneNumber ?? props.phoneNumber)

  return (
    <div className="px-2 md:px-8 lg:px-24 max-w-[1260px] mx-auto">
      <div className={'mt-0 sm:mt-12'}>
        <h2 className="title text-2xl sm:text-3xl md:text-4xl px-12 sm:px-0">{title}</h2>
        <p className="description px-8 sm:px-2 leading-tight text-base sm:text-base pb-8">{description}</p>
      </div>
      <div className={'-mt-3 sm:mt-0'}>
        <div className="grid md:grid-cols-3 gap-0 place-items-stretch bg-white shadow-xl mt-2 sm:mt-1 md:mt-0">
          {children}
        </div>
      </div>
      <div className={'mb-12'}>
        {buttonText && (
          <div className="pt-6 mt-2 px-1">
            <LandingPageButton textColor={buttonTextColor} color={buttonColor} buttonText={buttonText} />
          </div>
        )}
        {props.phoneNumber && (
          <div className={'mt-2'}>
            Or call us at <a href={`tel:${props.phoneNumber}`}>{phoneNumberTxt}</a>
          </div>
        )}
      </div>
    </div>
  )
}

export const IconGridItem: React.FC<{
  title: string
  description: string
  stepImage?: string
  children?: React.ReactNode
}> = ({ title, description, children }) => {
  return (
    <div
      className="w-full py-4 pt-8 flex flex-grow-1 flex-col h-100 text-center px-4 sm:px-6"
      style={{ border: '1px solid #dddddd', borderBottom: 'none' }}
    >
      <div className="mx-auto">{children}</div>
      <div className="mt-3">
        <h3>{title}</h3>
        <p className="description">{description}</p>
      </div>
    </div>
  )
}
