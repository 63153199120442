import { BasePageTemplateWithChildren } from '../templates/BaseLayoutTemplate'
import { Builder } from '@builder.io/react'
import { CityPageLayoutTemplateWithChildren } from '../templates/CityPageLayoutTemplate'
import { SEMPageTemplateWithChildren } from '../templates/SEMLayoutTemplate'

// Base Builder Page Layout Template
Builder.registerComponent(BasePageTemplateWithChildren, {
  name: 'Base Page Layout',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Page Title Here... - NuBrakes Mobile Brake Repair',
    },
    {
      name: 'description',
      type: 'string',
      defaultValue: 'Default Meta Description...',
    },

    {
      name: 'robots',
      type: 'string',
      defaultValue: 'index, follow',
      enum: [
        'noindex, nofollow',
        'index, follow',
        'index, nofollow',
        'noindex, follow',
        'nofollow, noimageindex',
        'follow, noimageindex',
      ],
      helperText:
        'Robots will not index and nofollow by default. You must change to Index, follow for full search engine visibility.',
    },
    { name: 'showGetQuote', type: 'boolean', defaultValue: true },
    { name: 'showFooter', type: 'boolean', defaultValue: true },
    { name: 'showNavBar', type: 'boolean', defaultValue: true },
    {
      name: 'ogImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      defaultValue: '/img/nubrakes-og-holder.png',
    },
    {
      name: 'phoneNumber',
      type: 'string',
      defaultValue: '8558005629',
    },
    {
      name: 'footerDisclaimer',
      type: 'richText',
    },
  ],
})

Builder.registerComponent(CityPageLayoutTemplateWithChildren, {
  name: 'City Page Layout',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Page Title Here... - NuBrakes Mobile Brake Repair',
    },
    {
      name: 'description',
      type: 'string',
      defaultValue: 'Default Meta Description...',
    },

    {
      name: 'robots',
      type: 'string',
      defaultValue: 'index, follow',
      enum: [
        'noindex, nofollow',
        'index, follow',
        'index, nofollow',
        'noindex, follow',
        'nofollow, noimageindex',
        'follow, noimageindex',
      ],
      helperText:
        'Robots will not index and nofollow by default. You must change to Index, follow for full search engine visibility.',
    },
    { name: 'showGetQuote', type: 'boolean', defaultValue: true },
    { name: 'showFooter', type: 'boolean', defaultValue: true },
    {
      name: 'ogImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      defaultValue: '/img/nubrakes-og-holder.png',
    },
    {
      name: 'phoneNumber',
      type: 'string',
      defaultValue: '8558005629',
    },
  ],
})

Builder.registerComponent(SEMPageTemplateWithChildren, {
  name: 'SEM Page Layout',
  inputs: [
    {
      name: 'robots',
      type: 'string',
      defaultValue: 'noindex, nofollow',
      enum: [
        'noindex, nofollow',
        'index, follow',
        'index, nofollow',
        'noindex, follow',
        'nofollow, noimageindex',
        'follow, noimageindex',
      ],
      helperText:
        'Robots will not index and nofollow by default. You must change to Index, follow for full search engine visibility.',
    },
    { name: 'showGetQuote', type: 'boolean', defaultValue: true },
    { name: 'showFooter', type: 'boolean', defaultValue: true },
    { name: 'showDefaultHeader', type: 'boolean', defaultValue: true },
    { name: 'showCityReviews', type: 'boolean', defaultValue: true },
    {
      name: 'formName',
      type: 'string',
      defaultValue: 'semScheduleServiceForm',
      helperText: 'Default is semScheduleServiceForm',
    },
    {
      name: 'ogImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      defaultValue: '/img/nubrakes-og-holder.png',
    },
  ],
})
