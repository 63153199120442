import * as React from 'react'
import dompurify from 'isomorphic-dompurify'
// import Image from 'next/image'
import { Container } from '../../../Container'
import { GraySection } from '../GraySection'

type ConvenientProps = {
  title: string
  description: string
  children?: any
}
export const ConvenientAffordableSection: React.FC<ConvenientProps> = ({ title, description, children }) => (
  <section className="text-center pt-6">
    <Container>
      <h2 className="title pt-0 sm:pt-2 text-3xl sm:text-4xl">{title}</h2>
      <div
        className="description text-base mb-4 sm:mb-2"
        dangerouslySetInnerHTML={{ __html: dompurify.sanitize(description) }}
      />
      <div className="grid md:grid-cols-3 gap-4">{children}</div>
    </Container>
  </section>
)

type BookFactProps = {
  title: string
  description?: string
  leftColumnTitle: string
  leftColumnText: string
  rightColumnTitle: string
  rightColumnText?: string
  children?: any
}

export const BookFactsSection: React.FC<BookFactProps> = ({
  title,
  description,
  leftColumnTitle,
  leftColumnText,
  rightColumnTitle,
  rightColumnText,
  children,
}) => (
  <GraySection className="text-center mb-16">
    <div className={'mb-12 px-4'}>
      <h2 className="pt-5 sm:pt-0 title text-center text-2xl mt-2 w-full">{title}</h2>
      <p className="description text-center px-12 sm:px-2">{description}</p>
      <div className="grid lg:grid-cols-12">
        <div className="lg:col-span-8 facts-block bg-white border-2 border-gray-100">
          <div className="text-left p-6 sm:p-12">
            <h3>{leftColumnTitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: leftColumnText }} />
          </div>
        </div>
        <div className="lg:col-span-4 facts-block border-2 border-gray-100 bg-white">
          <FactsSideBar sidebarTitle={rightColumnTitle} sidebarSubtitle={rightColumnText}>
            {children}
          </FactsSideBar>
        </div>
      </div>
    </div>
  </GraySection>
)

const FactsSideBar: React.FC<any> = ({ sidebarTitle, sidebarSubtitle, children }) => {
  return (
    <div className="p-6 pt-12">
      <div className="pb-3 mb-2">
        <h2 className="font-bold leading-tight text-2xl text-black mb-0">{sidebarTitle}</h2>
        <p className="font-bold leading-tight text-xl text-black">{sidebarSubtitle}</p>
      </div>
      <div className="text-center px-2">{children}</div>
    </div>
  )
}
