import * as React from 'react'
import { formatPhoneNumber } from '../../utils/string-utils'

export const CallUsNowBar: React.FC<{
  title?: string
  phoneNumber?: string
  backgroundColor?: string
}> = ({ title = 'Schedule A Brake Repair Or Get A Quote Now.', phoneNumber, backgroundColor }) => {
  return (
    <section
      style={{
        backgroundColor: backgroundColor,
        paddingTop: '3%',
        paddingBottom: '3%',
      }}
    >
      <div className="text-center hidden sm:flex flex-row items-center justify-center flex-wrap">
        <h3 className="m-0" style={{ color: 'white', lineHeight: 1.5 }}>
          {title}
        </h3>
        {phoneNumber && (
          <a href={`tel:${phoneNumber}`}>
            <button className="bg-transparent hover:bg-white hover:text-lime text-white border-white border-4 rounded p-3 ml-5 font-semibold">
              {formatPhoneNumber(phoneNumber)}
            </button>
          </a>
        )}
      </div>
      <div className="sm:hidden text-center px-4">
        <h3 className={'text-white text-2xl leading-tight mb-3 mt-2 sm:mb-0 sm:mt-0'}>{title}</h3>
        {phoneNumber && (
          <a href={`tel:${phoneNumber}`}>
            <button className="mb-3 sm:mb-0 bg-transparent hover:bg-white hover:text-lime text-white border-white border-4 rounded p-3 font-semibold">
              {formatPhoneNumber(phoneNumber)}
            </button>
          </a>
        )}
      </div>
    </section>
  )
}
