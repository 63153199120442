import React from 'react'
import { Builder, withChildren } from '@builder.io/react'
import { HowItWorksGreenCheckSection } from '../components/HowItWorksGreenCheck'
import { ConvenientAffordableSection } from '../components/cities/Convenient'
import { IconGridItem, IconGridSection } from '../components/IconGridSection'
import { CarBrandGrid } from '../components/CarBrandGrid'

// TODO: needs rework of component
const BuilderHowItWorksGreenCheckSection = ({ title, first, second, third }) => {
  return (
    <>
      {first && second && third && (
        <HowItWorksGreenCheckSection
          title={title}
          first={{ ...first[0] }}
          second={{ ...second[0] }}
          third={{ ...third[0] }}
        />
      )}
    </>
  )
}

Builder.registerComponent(BuilderHowItWorksGreenCheckSection, {
  name: 'How It Works Green Check Section',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Why NuBrakes?' },
    {
      name: 'first',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string', defaultValue: 'We Come To You' },
        {
          name: 'subtitle',
          type: 'string',
          defaultValue:
            'Our mobile brake techs deliver top-rated brake repairs at your home or office for up to 40% less than a repair shop.',
        },
      ],
    },
    {
      name: 'second',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string', defaultValue: 'Better Prices' },
        {
          name: 'subtitle',
          type: 'string',
          defaultValue:
            "We're 40% more affordable on average compared to the dealerships and shops, so you'll be saving time and money.",
        },
      ],
    },
    {
      name: 'third',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string', defaultValue: 'Same-Day Availability' },
        {
          name: 'subtitle',
          type: 'string',
          defaultValue:
            'Need your brakes repaired today? Same-day service is typically available — contact us to schedule!',
        },
      ],
    },
  ],
})

const IconGridItemWithChild = withChildren(IconGridItem)
const IconGridSectionWithChildren = withChildren(IconGridSection)

Builder.registerComponent(IconGridItemWithChild, {
  name: 'Icon Text Item',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Step 1' },
    { name: 'description', type: 'string', defaultValue: 'Step 1 description' },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'I am child text block!' } },
    },
  ],
  defaultStyles: {
    display: 'flex',
  },
})

Builder.registerComponent(IconGridSectionWithChildren, {
  name: 'Icon Grid Section',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'NuBrakes Mobile Repair' },
    { name: 'description', type: 'string', defaultValue: 'Mobile Auto Repair Built for You' },
    { name: 'phoneNumber', type: 'string' },
    { name: 'buttonText', type: 'string', defaultValue: 'Get A Quote' },
    { name: 'buttonColor', type: 'color', defaultValue: '#ffc107' },
    { name: 'buttonTextColor', type: 'color', defaultValue: '#ffffff' },
  ],
  defaultStyles: {
    display: 'flex',
    textAlign: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: '#fdfdfd',
  },
})

const ConvenientAffordableSectionWithChildren = withChildren(ConvenientAffordableSection)
Builder.registerComponent(ConvenientAffordableSectionWithChildren, {
  name: 'Simple Grid',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Convenient & Affordable' },
    { name: 'description', type: 'richText', defaultValue: '<p>We offer a variety of services to fit your needs.</p>' },
  ],
  defaultStyles: {
    textAlign: 'center',
    backgroundColor: 'rgba(243, 244, 246,1)',
  },
})

const BuilderCarBrandGrid = (props) => {
  const {
    builderState: {
      state: { carContent },
    },
    title,
  } = props
  return <CarBrandGrid title={title} carContent={carContent} {...props} />
}

const BuilderCarBrandGridWithChildren = withChildren(BuilderCarBrandGrid)

Builder.registerComponent(BuilderCarBrandGridWithChildren, {
  name: 'Car Brand Grid',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Skip The Brake Shop, We Offer Brake Services On Nearly Every Make & Model',
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'I am child text block!' } },
    },
  ],
})
