import { KlarnaAffirmHeaderInline } from '../components/HeroKlarnaAffirmInline'
import { Builder, withChildren } from '@builder.io/react'
import { HomeScheduleServiceFormHeader } from '../components/HomeScheduleServiceHeaderForm'
import React from 'react'
import HeaderSection from '../../SEMPage/HeaderSection'
import { ContentHeader } from '../components/ContentHeader'
import { BuilderTitleSection } from '../../LandingPage/TitleSection'
import { HomeScheduleServiceFormHeaderInline } from '../components/HeroInline'

export const BASE_WEB_APP_URL = `get-a-quote/services`

const BuilderKlarnaAffirmInlineHeader = ({
  builderState: {
    state: { rating, reviewCount },
  },
  showImage,
  showGradient,
  image,
  title,
  subtitle,
  subheading,
  headerTitle,
  eventVariationLabel,
  showDynamicTitle,
  ratingsText,
  isLinkForm,
  linkTarget,
  buttonText,
}) => {
  return (
    <KlarnaAffirmHeaderInline
      image={image}
      showImage={showImage}
      showGradient={showGradient}
      title={title}
      subtitle={subtitle}
      rating={rating}
      reviewCount={reviewCount}
      subheading={subheading}
      headerTitle={headerTitle}
      eventVariationLabel={eventVariationLabel}
      showDynamicTitle={showDynamicTitle}
      ratingsText={ratingsText}
      isLinkForm={isLinkForm}
      linkTarget={linkTarget}
      buttonText={buttonText}
    />
  )
}
const BuilderInlineHeader = ({
  builderState: {
    state: { rating, reviewCount },
  },
  showImage,
  showGradient,
  image,
  showVideo,
  video,
  title,
  subtitle,
  subheading,
  headerTitle,
  eventVariationLabel,
  showDynamicTitle,
  ratingsText,
  isLinkForm,
  linkTarget,
  buttonText,
}) => {
  return (
    <HomeScheduleServiceFormHeaderInline
      image={image}
      showImage={showImage}
      showGradient={showGradient}
      showVideo={showVideo}
      video={video}
      title={title}
      subtitle={subtitle}
      rating={rating}
      reviewCount={reviewCount}
      subheading={subheading}
      headerTitle={headerTitle}
      eventVariationLabel={eventVariationLabel}
      showDynamicTitle={showDynamicTitle}
      ratingsText={ratingsText}
      isLinkForm={isLinkForm}
      linkTarget={linkTarget}
      buttonText={buttonText}
    />
  )
}

// Inline New Inline Form Based Hero
// Inline New Inline Form Based Hero
Builder.registerComponent(BuilderInlineHeader, {
  name: 'Hero Inline Form',
  inputs: [
    { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'jpg', 'png'], helperText: '1280w x 768h works best' },
    { name: 'video', type: 'text', defaultValue: '', helperText: 'https://www.youtube.com/embed' },
    { name: 'title', type: 'string', defaultValue: 'Get a Free Quote Now', helperText: 'h1 Quote Form Title' },
    { name: 'showImage', type: 'boolean', defaultValue: 'true' },
    { name: 'showVideo', type: 'boolean', defaultValue: 'false' },
    {
      name: 'showDynamicTitle',
      type: 'boolean',
      defaultValue: 'false',
      helperText: 'This will hide the manually entered title above',
    },
    { name: 'showGradient', type: 'boolean', defaultValue: 'true' },
    {
      name: 'headerTitle',
      type: 'string',
      defaultValue:
        'We save our customers 40% on average over shop prices — book affordable repairs without leaving your home.',
    },
    {
      name: 'subtitle',
      type: 'string',
      defaultValue: 'Our repair experts will send you a quote in under 5 minutes.',
      helperText: 'Text under inline form',
    },
    {
      name: 'ratingsText',
      type: 'string',
      defaultValue: 'brake repair customers',
      helperText: 'Text after review count',
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Get A Free Quote',
    },
    { name: 'eventVariationLabel', type: 'string', helperText: 'Control/Test Variant Label' },
    {
      name: 'isLinkForm',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Links to Customer Web App When On',
      onChange: (options: Map<string, any>) => {
        if (options.get('isLinkForm') === true) {
          options.set('linkTarget', BASE_WEB_APP_URL)
        }
      },
    },
    {
      name: 'linkTarget',
      type: 'string',
      showIf: (options: Map<string, any>) => options.get('isLinkForm'),
      defaultValue: BASE_WEB_APP_URL,
      helperText: 'Customer Web App Link Pathname',
    },
  ],
})
Builder.registerComponent(BuilderKlarnaAffirmInlineHeader, {
  name: 'HeroInlineKlarnaAffirm',
  inputs: [
    { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'jpg', 'png'], helperText: '1280w x 768h works best' },
    { name: 'title', type: 'string', defaultValue: 'Get a Free Quote Now', helperText: 'h1 Quote Form Title' },
    { name: 'showImage', type: 'boolean', defaultValue: 'true' },
    {
      name: 'showDynamicTitle',
      type: 'boolean',
      defaultValue: 'false',
      helperText: 'This will hide the manually entered title above',
    },
    { name: 'showGradient', type: 'boolean', defaultValue: 'true' },
    {
      name: 'headerTitle',
      type: 'string',
      defaultValue:
        'We save our customers 40% on average over shop prices — book affordable repairs without leaving your home.',
    },
    {
      name: 'subtitle',
      type: 'string',
      defaultValue: 'Our repair experts will send you a quote in under 5 minutes.',
      helperText: 'Text under inline form',
    },
    {
      name: 'ratingsText',
      type: 'string',
      defaultValue: 'brake repair customers',
      helperText: 'Text after review count',
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Get A Free Quote',
    },
    { name: 'eventVariationLabel', type: 'string', helperText: 'Control/Test Variant Label' },
    {
      name: 'isLinkForm',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Links to Customer Web App When On',
      onChange: (options: Map<string, any>) => {
        if (options.get('isLinkForm') === true) {
          options.set('linkTarget', BASE_WEB_APP_URL)
        }
      },
    },
    {
      name: 'linkTarget',
      type: 'string',
      showIf: (options: Map<string, any>) => options.get('isLinkForm'),
      defaultValue: BASE_WEB_APP_URL,
      helperText: 'Customer Web App Link Pathname',
    },
  ],
})

const BuilderScheduleServiceFormHeader = (props) => {
  const {
    builderState: {
      state: { rating, reviewCount },
    },
  } = props
  return (
    <HomeScheduleServiceFormHeader
      title={props.title}
      subheading={props.subheading}
      rating={rating}
      reviewCount={reviewCount}
      ratingsText={props.ratingsText}
      showGradientOverlay={props.showGradientOverlay}
      bgImage={props.bgImage}
      showVideo={props.showVideo}
      video={props.video}
      {...props}
    />
  )
}

const BuilderScheduleServiceFormHeaderWithChildren = withChildren(BuilderScheduleServiceFormHeader)

// Hero that Takes Children in Quote Area (Wizard / Contact Us)
Builder.registerComponent(BuilderScheduleServiceFormHeaderWithChildren, {
  name: 'Header Insert Quote',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Get A Free Quote Now' },
    {
      name: 'subheading',
      type: 'string',
      defaultValue: 'Our repair experts will send you a quote in under 5 minutes.',
    },
    {
      name: 'ratingsText',
      type: 'string',
      defaultValue: 'brake repair customers',
    },
    {
      name: 'showGradientOverlay',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'bgImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: '1280w x 768h works best',
    },
    { name: 'showVideo', type: 'boolean', defaultValue: 'false' },
    { name: 'video', type: 'text', defaultValue: '', helperText: 'https://www.youtube.com/' },
    {
      name: 'eventVariationLabel',
      type: 'string',
      defaultValue: 'Hero Control Variant',
      helperText: 'What should the variant be labeled?',
    },
    { name: 'useDynamicTitle', type: 'boolean', defaultValue: false },
  ],
})

const BuilderHeaderSection = ({
  builderState: {
    state: { rating, reviewCount },
  },
  title,
  subtitle,
}) => <HeaderSection title={title} subtitle={subtitle} rating={rating} reviewCount={reviewCount} />

Builder.registerComponent(BuilderHeaderSection, {
  name: 'Header Section',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Title Text Here...' },
    {
      name: 'subtitle',
      type: 'string',
      defaultValue:
        'We save our customers 40% on average over shop prices — book affordable repairs without leaving your home.',
    },
  ],
})

// Basic Image Background Hero with Title Overlay (e.g. Warranty)
Builder.registerComponent(ContentHeader, {
  name: 'Content Header',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Page Title' },
    { name: 'description', type: 'string', defaultValue: 'Page Description' },
    { name: 'bgImageOverride', type: 'file', allowedFileTypes: ['jpeg', 'jpg', 'png'] },
  ],
})

Builder.registerComponent(BuilderTitleSection, {
  name: 'Title Section',
  inputs: [
    { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'jpg', 'png'] },
    { name: 'subheading', type: 'string', defaultValue: 'Mobile Auto Repair Built for You' },
    { name: 'title', type: 'string', defaultValue: 'NuBrakes Mobile Repair' },
    { name: 'phoneNumberTxt', type: 'string' },
    { name: 'phoneNumber', type: 'string' },
    { name: 'callToActionButtonText', type: 'string', defaultValue: 'Get A Quote' },
    { name: 'showImage', type: 'boolean', defaultValue: true },
    { name: 'showRatings', type: 'boolean', defaultValue: true },
    { name: 'showOverlay', type: 'boolean', defaultValue: false },
    { name: 'buttonColor', type: 'color', defaultValue: '#ffc107' },
  ],
  defaultStyles: {
    backgroundColor: '#000',
    display: 'flex',
    color: '#fff',
    padding: '1rem',
  },
})
