import * as React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { WizardScheduleServiceValues } from '../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormMultiStep'
import { Container } from '../Container'
import { StarsAndRatings } from './StarsAndRatings'
import { useQueryParam } from '../../hooks/useQueryParam'

type Props = {
  title: string
  rating?: string
  reviewCount?: number
  formInitialProps: WizardScheduleServiceValues
  sessionId?: string
  subtitle?: string
}
import backgroundImage from '../../../public/img/nubrakes-van-house-800-num.jpg'
import { NoEmailScheduleServiceFormMultiStepVariation } from '../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormVariation'

const HeaderSectionVariation: React.FC<Props> = (props) => {
  const {
    title,
    rating = '4.94',
    reviewCount = 6013,
    subtitle = 'We save our customers 40% on average over shop prices — book affordable repairs without leaving your home.',
  } = props
  const query = useQueryParam()
  return (
    <section className="py-0 text-white">
      <div
        className="py-8 flex flex-col justify-center align-center"
        style={{ minHeight: '60vh', position: 'relative', width: '100%' }}
      >
        <Image
          src={backgroundImage}
          placeholder="blur"
          layout={'fill'}
          priority={true}
          objectFit="cover"
          objectPosition="bottom center"
          alt="NuBrakes Header Variation Image"
        />
        <div
          id="overlay"
          style={{
            minHeight: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 1,
            backgroundColor: 'rgba(0,0,0,.75)',
          }}
        />
        <div style={{ zIndex: 2 }}>
          <Container className="text-center">
            <div className="grid md:grid-cols-2 gap-4">
              <div className="md:text-left text-center flex flex-col justify-center">
                <h1 style={{ fontSize: 32 }} className="title">
                  {title}
                </h1>
                <p className="subheading text-white">{subtitle}</p>
                <div
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.69)',
                    borderRadius: 6,
                  }}
                  className="p-3 flex-col flex-grow-0 md:flex hidden mr-auto text-center"
                >
                  <StarsAndRatings />
                  <p
                    style={{
                      color: '#cbd0d3',
                      lineHeight: 1,
                    }}
                  >
                    {`NuBrakes is rated ${rating}/5 by `}
                    <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                      <a
                        style={{
                          color: '#cbd0d3',
                          textDecoration: 'underline',
                        }}
                      >{`${reviewCount} brake repair customers`}</a>
                    </Link>
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <div style={{ backgroundColor: 'white' }} className="flex flex-col p-4 mt-0">
                  <NoEmailScheduleServiceFormMultiStepVariation />
                </div>
                <div className="mt-3 block md:hidden">
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.69)',
                      borderRadius: 6,
                    }}
                    className="inline-block px-3 py-2"
                  >
                    <StarsAndRatings />
                    <p
                      style={{
                        color: '#cbd0d3',
                        lineHeight: 1,
                      }}
                    >
                      {`NuBrakes is rated ${rating}/5 by `}
                      <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                        <a
                          style={{
                            color: '#cbd0d3',
                            textDecoration: 'underline',
                          }}
                        >{`${reviewCount} brake repair customers`}</a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  )
}

type BuilderProps = {
  title: string
  subtitle?: string
  showRatings?: boolean
  image?: string
  showImage?: boolean
  rating?: string
  reviewCount?: number
  formInitialProps: WizardScheduleServiceValues
  sessionId?: string
  showOverlay?: boolean
  overlayColor?: string
  quoteTitle?: string
  quoteSubtitle?: string
}

export const BuilderHeaderSectionVariation: React.FC<BuilderProps> = (props) => {
  const {
    title,
    subtitle,
    showRatings,
    image,
    showImage,
    showOverlay,
    overlayColor,
    quoteTitle,
    quoteSubtitle,
    rating = '4.94',
    reviewCount = '2502',
  } = props
  const query = useQueryParam()
  return (
    <section className="py-0 text-white">
      <div
        className="py-8 flex flex-col justify-center align-center"
        style={{ minHeight: '60vh', position: 'relative', width: '100%' }}
      >
        {showImage && (
          <Image
            src={image ?? backgroundImage}
            layout={'fill'}
            priority={true}
            objectFit="cover"
            objectPosition="bottom center"
            alt="NuBrakes Header Variation Image"
          />
        )}
        {showOverlay && (
          <div
            id="overlay"
            style={{
              minHeight: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 1,
              backgroundColor: `${overlayColor ?? 'rgba(0,0,0,.75)'}`,
            }}
          />
        )}
        <div style={{ zIndex: 2 }}>
          <Container className="text-center">
            <div className="grid md:grid-cols-2 gap-4">
              <div className="md:text-left text-center flex flex-col justify-center">
                <h1 style={{ fontSize: 32 }} className="title">
                  {title}
                </h1>
                <p style={{ fontSize: 18, marginBottom: 10 }} className="subheading">
                  {subtitle}
                </p>
                {showRatings && (
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.69)',
                      borderRadius: 6,
                    }}
                    className="p-3 flex-col flex-grow-0 md:flex hidden mr-auto text-center"
                  >
                    <StarsAndRatings />
                    <p
                      style={{
                        color: '#cbd0d3',
                        lineHeight: 1,
                      }}
                    >
                      {`NuBrakes is rated ${rating}/5 by `}
                      <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                        <a
                          style={{
                            color: '#cbd0d3',
                            textDecoration: 'underline',
                          }}
                        >{`${reviewCount} brake repair customers`}</a>
                      </Link>
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-2">
                <div style={{ backgroundColor: 'white' }} className="flex flex-col p-4 mt-0">
                  <div className="text-center text-black">
                    {quoteTitle && <h3>{quoteTitle}</h3>}
                    {quoteSubtitle && <p>{quoteSubtitle}</p>}
                  </div>
                  <NoEmailScheduleServiceFormMultiStepVariation />
                </div>
                <div className="mt-3 block md:hidden">
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.69)',
                      borderRadius: 6,
                    }}
                    className="inline-block px-3 py-2"
                  >
                    <StarsAndRatings />
                    <p
                      style={{
                        color: '#cbd0d3',
                        lineHeight: 1,
                      }}
                    >
                      {`NuBrakes is rated ${rating}/5 by `}
                      <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                        <a
                          style={{
                            color: '#cbd0d3',
                            textDecoration: 'underline',
                          }}
                        >{`${reviewCount} brake repair customers`}</a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  )
}

export default HeaderSectionVariation
