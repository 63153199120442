import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Container } from '../../Container'
import {
  twDefaultInitialWizardFormValues,
  NoEmailScheduleServiceFormMultiStepCompact,
} from '../../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormMultiStep'
import { StarsAndRatings } from '../../SEMPage/StarsAndRatings'
import { useQueryParam } from '../../../hooks/useQueryParam'
import backgroundImage from '../../../../public/img/new_tech_1.webp'
import { getTitleFromUTMS } from '../../SEMPage/getTitleFromUTMS'
import { propOr } from 'ramda'
import * as changeCase from 'capital-case'
import { CityPhoneNumberData } from '../../SEMPage/CityPhoneNumberData'
import { formatPhoneNumber } from '../../utils/string-utils'
import { setContentWithFallbacks } from '../templates/SEMLayoutTemplate'

export const HomeScheduleServiceFormHeaderInline: React.FC<{
  title: string
  subtitle?: string
  rating: string
  reviewCount: number
  subheading: string
  image?: string
  showImage?: boolean
  showGradient?: boolean
  showVideo?: boolean
  video?: string
  headerTitle?: string
  eventVariationLabel?: string
  showDynamicTitle?: boolean
  ratingsText?: string
  isLinkForm?: boolean
  linkTarget?: string
  buttonText?: string
}> = ({
  title,
  subtitle,
  rating,
  reviewCount,
  image,
  showImage = true,
  showGradient = true,
  showVideo = false,
  video,
  headerTitle,
  showDynamicTitle = false,
  ratingsText,
  isLinkForm,
  buttonText,
  linkTarget,
}) => {
  const query = useQueryParam()
  // TODO refactor the data here
  const city: string = propOr('', 'city', query)
  const type: string = propOr('', 'type', query)
  const make: string = propOr('', 'make', query)
  let content: string = propOr('', 'utm_content', query)
  content = setContentWithFallbacks(type, content)
  const formalCity = city ? changeCase.capitalCase(city) : ''
  const phoneNumber =
    city && CityPhoneNumberData[city] ? CityPhoneNumberData[city!].phoneNumber : CityPhoneNumberData.default.phoneNumber
  const phoneNumberTxt = formatPhoneNumber(phoneNumber)

  const dynamicTitle = getTitleFromUTMS({
    formalCity,
    phoneNumber,
    phoneNumberTxt,
    type,
    content,
    make,
    rating,
    reviewCount,
  })

  return (
    <>
      <section className="flex relative min-h-[80vh] sm:min-h-[90vh] pb-0 sm:pb-4">
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: -100,
            top: 0,
            left: 0,
          }}
        >
          <div className="pb-0 flex justify-center align-center w-full h-full relative">
            {showImage && (
              <div className={'w-full overflow-hidden relative bg-[#111] block mx-auto'}>
                <Image
                  src={image ?? backgroundImage}
                  layout="responsive"
                  priority={true}
                  height={768}
                  width={1200}
                  alt={'header image background'}
                  className="mx-auto block bg-radial-dark object-fit-contain md:object-fit-cover lg:object-fill w-full relative h-auto lg:object-top"
                />
                {showGradient && (
                  <div
                    className={
                      'absolute w-full bottom-1/2 sm:bottom-[300px] md:bottom-[200px] lg:bottom-0 left-0 h-[200px] sm:h-[200px] lg:h-[320px] image-gradient-overlay'
                    }
                    style={{
                      background: 'linear-gradient(to bottom, transparent 0%, #333 50%, #222 90%, #111 100%)',
                      zIndex: 100,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <Container className="flex text-center z-10 w-full">
          <div className="mt-4 mb-6 flex self-end flex-col w-full items-center">
            { showVideo && (
              <div className='max-w-3xl w-full order-3 md:order-1'>
                <div className='mt-10 md:mb-10 pb-[52%] w-full justify-center flex relative'>
                  <div className="absolute top-0 left-0 right-0 bottom-0">
                    <iframe
                      className="w-full h-full"
                      src={video}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className={`inline-wizard max-w-[850px] w-full self-center p-4 lg:mt-0 text-left bg-white rounded-sm shadow-black shadow-lg pb-8 order-2`}
            >
              <div className="text-center">
                {showDynamicTitle ? (
                  <h1 className="mb-0 mt-1 text-xl sm:text-2xl md:text-3xl lg:text-4xl leading-none title-wizard px-0 sm:px-8 md:px-16">
                    {dynamicTitle}
                  </h1>
                ) : (
                  <h1 className="mb-1 sm:mb-0 mt-1 text-2xl sm:text-3xl md:text-3xl lg:text-4xl leading-none title-wizard px-0 sm:px-8 md:px-16">
                    {title}
                  </h1>
                )}

                {headerTitle && (
                  <p
                    className={
                      'text-gray-500 text-sm sm:text-base md:text-xl mb-2 pb-1 md:mb-2 mt-0 px-8 sm:px-4 leading-tight sub-title-wizard'
                    }
                  >
                    {headerTitle}
                  </p>
                )}
              </div>
              <NoEmailScheduleServiceFormMultiStepCompact
                initialValues={{
                  ...twDefaultInitialWizardFormValues,
                  'form-name': 'getQuoteScheduleService',
                }}
                isLinkForm={isLinkForm}
                buttonText={buttonText}
                linkTarget={linkTarget}
              />
              <p className="text-center text-sm px-8 sm:px-16 mt-2 mb-0 pb-0 leading-tight text-nugrey">{subtitle}</p>
            </div>

            <div className="mt-1 mb-0 sm:mt-6 lg:mt-1 block text-center order-3">
              <div className="inline-block w-full px-3 pt-2 lg:flex lg:flex-col justify-center mt-2 lg:mt-1">
                <div className="mt-0">
                  <StarsAndRatings />
                </div>

                <p className="text-gray-50 leading-tight">
                  {`NuBrakes is rated`}
                  <span className={'text-red-600 leading-tight font-bold'}>{` ${rating}/5 `}</span>
                  <span className={'hidden sm:inline'}>by </span>

                  <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                    <a className="-mt-1 sm:mt-0 text-[#cbd0d3] sm:text-gray-50 underline block lg:inline-block">{`${reviewCount} ${ratingsText}`}</a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
