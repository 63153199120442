import * as React from 'react'
import { Container } from '../Container'
import { ReasonWhyItem } from '../BecomeATechnicianPage/WhatToExpectSection'
import { faHandHoldingUsd } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingUsd'
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench'
import { faBoxFull } from '@fortawesome/pro-duotone-svg-icons/faBoxFull'
import { faGarageCar } from '@fortawesome/pro-duotone-svg-icons/faGarageCar'
import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck'
import { faUserHeadset } from '@fortawesome/pro-duotone-svg-icons/faUserHeadset'

export const HomePageThirdSection: React.FC = () => {
  return (
    <section>
      <Container className="text-center">
        <h1>YOU WON’T FIND A MORE CONVENIENT BRAKE REPAIR SERVICE.</h1>
        <p className="description">
          Your time is precious, so why waste it sitting around at an shop that tries to sell you on repairs you don’t
          actually need?
        </p>
        <div className="grid md:grid-cols-3 gap-4">
          <ReasonWhyItem
            title={`Save On Time & Money`}
            subheading={`Don’t waste your afternoon at a shop. We save our customers nearly 40% on average over shop pricing, and you never have to leave your couch.`}
            icon={faHandHoldingUsd}
          />
          <ReasonWhyItem
            title={`Experienced Brake Mechanics`}
            subheading={`Our certified brake techs have decades of combined experience repairing vehicles, so you’re always in good hands.`}
            icon={faWrench}
          />
          <ReasonWhyItem
            title={`Keep Your Vehicle At Your Home or Work`}
            subheading={`Since we come to you, there’s no need to leave your vehicle sitting on a shop lot for hours or even days unattended.`}
            icon={faGarageCar}
          />
          <ReasonWhyItem
            title={`Premium Brake Parts`}
            subheading={`We offer premium brake parts with no markup, something that can’t be said by the big box shops.`}
            icon={faBoxFull}
          />
          <ReasonWhyItem
            title={`NuBrakes Guarantee`}
            subheading={`Our repairs are backed by our NuBrakes Guarantee to provide peace of mind. Read our warranty for more information.`}
            icon={faShieldCheck}
          />
          <ReasonWhyItem
            title={`UnMatched Customer Service`}
            subheading={`We’re proud to offer some of the best customer service in the business. From scheduling to general questions, we’ve got your back.`}
            icon={faUserHeadset}
          />
        </div>
      </Container>
    </section>
  )
}
