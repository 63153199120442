import React from 'react'
import { withChildren } from '@builder.io/react'
import Layout from '../../Layout'
import { getTitleFromUTMS } from '../../SEMPage/getTitleFromUTMS'
import Head from 'next/head'
import { twDefaultInitialWizardFormValues } from '../../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormMultiStep'
import HeaderSectionVariation from '../../SEMPage/HeaderSectionVariation'
import { useRouter } from 'next/router'
import { propOr } from 'ramda'
import * as changeCase from 'capital-case'
import { CityPhoneNumberData } from '../../SEMPage/CityPhoneNumberData'
import { formatPhoneNumber } from '../../utils/string-utils'

const SEMPageTemplate: React.FC<any> = ({
  title,
  description,
  robots,
  showGetQuote,
  showFooter,
  showDefaultHeader,
  ogImage,
  rating,
  reviewCount,
  formName,
  children,
}) => {
  const router = useRouter()
  const { query } = router
  const city: string = propOr('', 'city', query)
  const type: string = propOr('', 'type', query)
  const make: string = propOr('', 'make', query)
  let content: string = propOr('', 'utm_content', query)
  content = setContentWithFallbacks(type, content)
  const formalCity = city ? changeCase.capitalCase(city) : ''
  const phoneNumber =
    city && CityPhoneNumberData[city] ? CityPhoneNumberData[city!].phoneNumber : CityPhoneNumberData.default.phoneNumber
  const phoneNumberTxt = formatPhoneNumber(phoneNumber)

  const headerTitle = getTitleFromUTMS({
    formalCity,
    phoneNumber,
    phoneNumberTxt,
    type,
    content,
    make,
    rating,
    reviewCount,
  })

  return (
    <SEMLayoutWithChildren
      description={description}
      robots={robots}
      showGetQuote={showGetQuote}
      showFooter={showFooter}
      title={headerTitle}
      ogImage={ogImage}
      phoneNumber={phoneNumber}
      phoneNumberTxt={phoneNumberTxt}
    >
      <Head>
        <meta
          name="description"
          key={'description'}
          content={`Need new brakes? Stay at home and let a certified NuBrakes brake tech come to you and repair your brakes for less than most${
            formalCity ? ` ${formalCity}` : ''
          } brake shops and dealerships on average.`}
        />
        <meta name="robots" content="noindex,nofollow" />
        <title>
          Affordable Brake Repair Services {formalCity ? ` In ${formalCity}` : ''} | NuBrakes Mobile Brake Repair - We
          Come To You
        </title>
      </Head>
      {showDefaultHeader && (
        <HeaderSectionVariation
          rating={rating}
          formInitialProps={{
            ...twDefaultInitialWizardFormValues,
            'form-name': formName,
          }}
          reviewCount={reviewCount}
          title={title ?? headerTitle}
        />
      )}
      {children}
    </SEMLayoutWithChildren>
  )
}

export function setContentWithFallbacks(type: string, content: string) {
  if (content !== 'undefined') {
    return content
  }
  switch (type) {
    case 'generic':
      return ''
    case 'comp':
      return 'brake shop'
    case 'vehicle':
      return ''
    case 'vehicle-part':
      return 'brake-parts'
    case 'mm_generic':
      return 'auto-parts'
    case 'mm_vehicle-part':
      return 'auto-parts'
    default:
      return ''
  }
}

// Exported Here for use in order to allow builder children while keeping the Layout Component in tact for other pages
export const SEMLayoutWithChildren = withChildren(Layout) // Overall HTML BODY & HEAD
export const SEMPageTemplateWithChildren = withChildren(SEMPageTemplate) // Layout extended for Builder & Children
