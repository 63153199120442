import Image, { ImageProps } from 'next/image'
import backgroundImage from '../../public/img/nubrakes-brake-repair-header.jpg'

export const BgImage = ({ src, children }: { src: ImageProps['src']; children: any }) => {
  return (
    <div className="flex flex-col justify-center align-center relative h-[45vh] sm:h-[60vh]">
      <Image
        src={src ?? backgroundImage}
        alt="NuBrakes Background Image"
        layout={'fill'}
        objectFit="cover"
        objectPosition="top left"
      />
      <div style={{ zIndex: 1 }}>{children}</div>
    </div>
  )
}
