import * as React from 'react'
import { useSource } from '../../../hooks/useSource'
import { WizardVariation } from './WizardVariation'
import VehicleInfoFormSection, { twVehicleInfoValidationSchema } from './VehicleInfoFormSection'
import { generateFakeEmail } from '../../../util/form-util'

export const twDefaultInitialWizardFormValues = {
  'form-name': 'scheduleServiceWizard',
  fullName: '',
  email: generateFakeEmail(null),
  extraInfo: '',
  vehicleYear: '',
  phoneNumber: '',
  zip: '',
  hearAboutUs: '',
  make: '',
  model: '',
  symptoms: '',
  referredById: '',
  source: '',
  serviceUrgency: '',
  referrerSource: '',
}

export type WizardScheduleServiceValues = {
  fullName: string
  'form-name': string
  email: string
  phoneNumber: string
  vehicleYear: string
  zip: string
  make: string
  model: string
  extraInfo: string
  source: string
  referredById: string
  hearAboutUs: string
  symptoms: string
  serviceUrgency: string
  referrerSource: string
}

export const NoEmailScheduleServiceFormMultiStepVariation: React.FC<{
  initialValues?: WizardScheduleServiceValues
}> = ({ initialValues = twDefaultInitialWizardFormValues }) => {
  const source = useSource()

  return (
    <WizardVariation
      initialValues={{ ...initialValues, source }}
      formPages={[
        {
          element: VehicleInfoFormSection,
          validationSchema: twVehicleInfoValidationSchema,
        },
      ]}
    />
  )
}
