import * as React from 'react'
import Link from 'next/link'
import * as changeCase from 'capital-case'
import { pipe, take } from 'ramda'
import { mapIndexed } from '../../util/util'
import { paramCase } from 'param-case'
import { Container } from '../Container'
import { useQueryParam } from '../../hooks/useQueryParam'
import Image from 'next/image'

export const CarsRollWhite: React.FC<{ carContent: any[] }> = ({ carContent }) => {
  return (
    <section className="text-center">
      <Container>
        <h2 className="title">Skip The Brake Shop, We Offer Brake Services On Nearly Every Make & Model</h2>
        <p className="description">
          Our mobile brake mechanics are experienced and ready to tackle brake repairs on nearly all major makes and
          models. Plus, we beat most dealership repair quotes by up to 40% on average!
        </p>
        <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-4">
          {pipe(
            take(8),
            mapIndexed((post: any, index) => (
              <CarsRollItem
                key={index}
                logo={post.makeLogo}
                url={`/cars/${paramCase(post.makeShortName)}-brake-repair/`}
                makeLongName={post.makeLongName}
              />
            ))
          )(carContent) as any}
        </div>
      </Container>
    </section>
  )
}

export const CarsRollItem: React.FC<{
  logo: any
  url: string
  makeLongName: string
}> = ({ logo, makeLongName, url }) => {
  const query = useQueryParam()
  return (
    <div className="pb-5">
      <Link as={{ pathname: url, query }} href={'/cars/[slug]'}>
        <a>
          <div className="shadow-lg mx-auto" style={{ border: '1px solid #dddddd', width: 200, height: 200 }}>
            <Image src={logo} width={200} height={200} alt={`${changeCase.capitalCase(makeLongName)} Logo`} />
          </div>
          <div className="pt-4">{`${changeCase.capitalCase(makeLongName)} Brake Repair`}</div>
        </a>
      </Link>
    </div>
  )
}
