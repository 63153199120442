import * as React from 'react'
import { Container } from '../Container'
import { GraySection } from '../GraySection'
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench'
import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingUsd } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingUsd'
import { faSeedling } from '@fortawesome/pro-duotone-svg-icons/faSeedling'
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers'

export const WhatToExpectSection: React.FC = () => {
  // const item = {
  //   title: 'REPAIRS AROUND YOUR SCHEDULE',
  //   subheading:
  //     'Life’s busy. So why waste your time sitting at a shop? Our brake techs come to you and repair your brakes around your schedule.',
  //   icon: faWrench,
  // }
  return (
    <GraySection>
      <Container className={'text-center'}>
        <h1>What To Expect</h1>
        <p className="description">
          Our technicians are the backbone of our business and we treat all team members like family. When you join the
          NuBrakes team, you’ll be given the tools and support needed to succeed.
        </p>
        <div className="grid md:grid-cols-3 gap-4">
          <ReasonWhyItem
            title={'Top Industry Pay'}
            icon={faHandHoldingUsd}
            subheading={
              'Our pay plan puts the ball in your court! We offer a guaranteed base salary, but there is no limit on what you can earn! Almost all of our techs exceed their base pay in a matter of weeks.'
            }
          />
          <ReasonWhyItem
            title={'Opportunity for growth'}
            icon={faSeedling}
            subheading={
              'We’re a high-growth startup and we’re reinventing the brake repair industry. We believe in a hire-from-within mentality and a high-growth company means opportunity to move up in your career.'
            }
          />
          <ReasonWhyItem
            title={`A Sense Of Team & Belonging`}
            icon={faUsers}
            subheading={
              'Team is core to NuBrakes. We all help each other & work as a team to serve our customers. Our goal is to provide you with the support and tools you need to be successful each and every day.'
            }
          />
        </div>
        <div className="grid md:grid-cols-2 gap-4">
          <ReasonWhyItem
            title={`Top-NOTCH SERVICE VEHICLE & EQUIPMENT`}
            icon={faWrench}
            subheading={
              'We’ll provide you with a brand new, 2020 Nissan NV200 company service vehicle and all of the tools/equipment you need to deliver a 5 star service.'
            }
          />
          <ReasonWhyItem
            title={`OFFICE & CUSTOMER SUPPORT`}
            icon={faShieldCheck}
            subheading={
              'Our Customer Service and Dispatch team is here to back you up. We have a stellar staff handling the customer interaction so you can focus on the task at hand.'
            }
          />
        </div>
      </Container>
    </GraySection>
  )
}

export const ReasonWhyItem: React.FC<{
  title: string
  subheading: string
  icon: any
}> = ({ title, subheading, icon }) => {
  return (
    <div className="flex flex-grow flex-col h-100 py-8">
      <p>
        <FontAwesomeIcon size={'4x'} icon={icon} color={'#ea3616'} />
      </p>
      <div className="mt-auto">
        <h2 style={{ paddingTop: 20, fontSize: 18, fontWeight: 900 }}>{title}</h2>
        <p className="m-0 description">{subheading}</p>
      </div>
    </div>
  )
}
