import * as React from 'react'
import { Container } from '../Container'
import { GraySection } from '../GraySection'
import StarRatingComponent from 'react-star-rating-component'
import { LandingPageButton } from '../LandingPageButton'
import { formatPhoneNumber } from '../utils/string-utils'
import Image from 'next/image'

import google from '../../../public/img/google-logo-small.png'
import yelp from '../../../public/img/yelp-logo-small.png'
import facebook from '../../../public/img/facebook-logo.png'
import nubrakes from '../../../public/img/nubrakes-logo-icon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons'

type Props = {
  title: string
  description: string
  reviews: ReviewProps[]
  rating?: string
  reviewCount?: number
  phoneNumber?: string
  subtitle?: string
}

export const FiveStar = () => (
  <StarRatingComponent
    name={'rating'} /* name of the radio input, it is required */
    value={5} /* number of selected icon (`0` - none, `1` - first) */
    starCount={5} /* number of icons in rating, default `5` */
    starColor={'gold'} /* color of selected icons, default `#ffb400` */
    editing={false} /* is component available for editing, default `true` */
    renderStarIcon={(index, value) => {
      return (
        <span>
          {index <= value ? (
            <FontAwesomeIcon icon={faStar} color="gold" />
          ) : (
            <FontAwesomeIcon icon={faStar} color="gold" />
          )}
        </span>
      )
    }}
    renderStarIconHalf={() => {
      return (
        <span>
          <FontAwesomeIcon icon={faStarHalf} color={'gold'} />
        </span>
      )
    }}
  />
)
export const ReviewsSection: React.FC<Props> = ({ title, phoneNumber, subtitle, reviews, rating, reviewCount }) => {
  return (
    <GraySection className="text-center">
      <Container>
        <h1>{title}</h1>
        <div>
          <FiveStar />
        </div>
        {reviewCount && rating && (
          <p className={'description px-6 sm:px-2'}>
            Average rating of <span className={'text-orange-500 font-bold'}>{rating}/5</span> from{' '}
            <span className={'block sm:inline text-orange-500 font-bold text-xl sm:text-base'}>
              {reviewCount} brake repair customers
            </span>
          </p>
        )}

        <div className="grid md:grid-cols-3 gap-4 mt-8">
          {reviews.map((value, index) => (
            <ReviewComponent {...value} key={index} />
          ))}
        </div>
        {subtitle ? <p className="text-base text-gray-600 mt-4">{subtitle}</p> : null}
        <div className="pt-5">
          <LandingPageButton buttonText={'Get A Quote'} />
        </div>
        {phoneNumber && (
          <div className={'mt-2'}>
            Or call us at <a href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
          </div>
        )}
      </Container>
    </GraySection>
  )
}
export type ReviewProps = {
  review: string
  reviewSource: string
  customerName: string
  customerLocation: string
  reviewLink: string
}

export const ReviewComponent: React.FC<ReviewProps> = ({
  review,
  customerName,
  customerLocation,
  reviewLink,
  reviewSource,
}) => {
  const getImageForSource = (reviewSource: string) => {
    switch (reviewSource) {
      case 'facebook':
        return facebook
      case 'google':
        return google
      case 'yelp':
        return yelp
      default:
        return nubrakes
    }
  }

  const image = getImageForSource(reviewSource)
  return (
    <>
      <div className={'flex flex-col h-100 px-5 bg-white py-4 shadow-xl'}>
        <div className="flex flex-row justify-between items-center">
          <a href={reviewLink}>
            <div style={{ width: 80, height: 30 }}>
              <Image src={image ?? nubrakes} alt={'Social Media Source'} layout="responsive" />
            </div>
          </a>
          <FiveStar />
        </div>
        <div className="text-left pt-4">
          <p style={{ fontSize: 16 }}>“{review}”</p>
        </div>
        <div className="mt-auto text-left">
          <p className="customer-name font-bold">
            — {customerName} | {customerLocation}
          </p>
        </div>
      </div>
    </>
  )
}

const defaultReviewData = [
  {
    review:
      'I called and within 15 minutes had a few price options with no sales pressure. They ordered the parts we agreed upon and came to my office and within 90 minutes my brakes were fixed. No waiting around, no sales pressure and hundreds of dollars less than a traditional repair shop. Highly recommend.',
    reviewSource: `google`,
    customerLocation: 'Austin, Texas',
    customerName: 'Stephen T.',
    reviewLink: 'https://goo.gl/maps/Ks8Tec69t5x5sFX68',
  },
  {
    review:
      'These guys are great. Excellent Customer Service: My experience from the very start with getting a quote and scheduling with Walker, and the technician Mike doing the brake repair job was great. Came to my house on time as expected. No pressure Up-front, no haggle pricing. Much less expensive than going to the dealership. I highly recommend and I will certainly continue to do business with NuBrakes.',
    reviewSource: `yelp`,
    customerLocation: 'Dallas, Texas',
    customerName: 'Reid H.',
    reviewLink: 'https://www.yelp.com/biz/nubrakes-mobile-brake-repair-dallas',
  },
  {
    review:
      'Walker and Michael saved me today after my brakes gave out on my way to Dallas for a conference. Walker was incredibly responsive and able to chat via text while I was in my meeting. He and Michael were informative and knowledgeable. Michael showed up on time and provided quick service; from start to finish the service took less time than my lunch break! I am so grateful for this company and highly recommend them!',
    reviewSource: `facebook`,
    customerLocation: 'Austin, Texas',
    customerName: 'Brittany M.H.',
    reviewLink: 'https://www.facebook.com/brittany.m.horvath/posts/10100148148651320',
  },
]

export const BuilderReviewsSection = (props) => (
  <ReviewsSection
    reviews={defaultReviewData}
    rating={props.builderState?.state?.rating}
    reviewCount={props.builderState?.state?.reviewCount}
    {...props}
  />
)
