import { Field, Form, Formik, FormikProps } from 'formik'
import { useFormAnalytics } from '../../../hooks/useFormAnalytics'
import { Button } from '../../Button'
import React, { useState } from 'react'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { FormPages, WizardScheduleServiceValues } from './Wizard'
import { useSource } from '../../../hooks/useSource'
import VehicleInfoFormSection, {
  twVehicleInfoValidationSchema,
} from '../../Forms/ScheduleServiceMultiStep/VehicleInfoFormSection'
import { twDefaultInitialWizardFormValues } from '../../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormVariation'
import { serializeLinkWithParams } from '../../../util/util'
import { CUSTOMER_WEBAPP_URL } from '../../../pages/_app'

export const WizardVariation: React.FC<{
  initialValues: WizardScheduleServiceValues
  title?: string
  subtitle?: string
  linkTarget?: string
  buttonText?: string
  formPages: FormPages[]
}> = ({ initialValues, formPages, title, subtitle, linkTarget, buttonText }) => {
  const [page] = useState(0)
  const activePage = formPages[page].element
  const length = formPages.length
  const validationSchema = formPages[page].validationSchema
  return (
    <div className={'text-left text-black'}>
      <div className="text-center">
        <h3 className='text-lg md:text-xl'>{title ?? `Get A Free Quote Now`}</h3>
        <p>{subtitle ?? `Our repair experts will send you a quote in under 5 minutes.`}</p>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={false}
        validationSchema={validationSchema}
        onSubmit={() => {
          console.log('submitted')
        }}
      >
        {(props) => (
          <InnerForm
            {...props}
            activePage={activePage}
            page={page}
            length={length}
            buttonText={buttonText}
            linkTarget={linkTarget}
          />
        )}
      </Formik>
    </div>
  )
}

const InnerForm: React.FC<
  FormikProps<any> & {
    page: number
    activePage: any
    length: number
    linkTarget?: string
    buttonText?: string
  }
> = (props) => {
  const { values, isValid, activePage, linkTarget, setFieldValue, buttonText } = props
  const query = useQueryParam()
  useFormAnalytics(values)

  const ymmValues = {
    vehicleYear: values?.vehicleYear,
    vehicleMake: values?.make,
    vehicleModel: values?.model,
  }

  return (
    <Form name="scheduleServiceWizard">
      <Field type="hidden" name="form-name" value={'scheduleServiceWizard'} />
      <Field type="hidden" name="email" />
      {React.createElement(activePage, props)}
      <div className="px-2">
        <Button
          disabled={!isValid}
          className={`w-full py-2`}
          onClick={async (e) => {
            e.preventDefault() // Don't let the form submit this is a link out
            setFieldValue('referrerSource', document.referrer, false)
            // Record GA Event
            if (window['dataLayer'] && window['location']) {
              window['dataLayer'].push({ event: 'nextClicked' })
              const variation = sessionStorage.getItem('experimentVariation');
              const linkParams: any = { ...query, ...ymmValues }
              if (variation) {
                linkParams.variation = variation;
              }
              window['location'].assign(
                serializeLinkWithParams(`https://${CUSTOMER_WEBAPP_URL}/${linkTarget}`, linkParams)
              )
            }
          }}
        >
          {buttonText}
        </Button>
      </div>
    </Form>
  )
}

export const NoEmailScheduleServiceFormMultiStepLinkOut: React.FC<{
  linkTarget?: string
  buttonText?: string
  subtitle?: string
  initialValues?: WizardScheduleServiceValues
}> = ({ initialValues = twDefaultInitialWizardFormValues, linkTarget, buttonText, subtitle }) => {
  const source = useSource()

  return (
    <WizardVariation
      linkTarget={linkTarget}
      buttonText={buttonText}
      subtitle={subtitle}
      initialValues={{ ...initialValues, source }}
      formPages={[
        {
          element: VehicleInfoFormSection,
          validationSchema: twVehicleInfoValidationSchema,
        },
      ]}
    />
  )
}
