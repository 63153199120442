import React from 'react'
import Modal from 'react-modal'
import { Container } from './Container'
import { flattenObj, unflatten } from '../util/util'

type NuModalProps = {
  isOpen: boolean
  toggleModal: () => void
  contentLabel: string
  children: React.ReactNode
  preventScroll?: boolean
  shouldCloseOnOverlayClick?: boolean
  onRequestClose?: () => void
  appCSSSelector?: string
  maxWidth?: string
  modalStyle?: Modal.Styles
}

const defaultStyle = {
  content: {
    padding: 0,
    maxWidth: `'100%'`,
    margin: '0 auto',
    display: 'flex',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-45%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '1em',
  },
  overlay: {
    zIndex: 40,
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
  },
}

export const NuModal = ({
  isOpen,
  toggleModal,
  children,
  contentLabel,
  onRequestClose,
  shouldCloseOnOverlayClick,
  appCSSSelector,
  modalStyle,
}: NuModalProps) => {
  Modal.setAppElement(appCSSSelector ?? '#__next') // default to the body (NextJS App)
  const style = unflatten(Object.assign(flattenObj(defaultStyle), flattenObj(modalStyle)))

  return (
    <Modal
      isOpen={isOpen}
      style={style}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      contentLabel={contentLabel}
    >
      <div className={'px-4 relative'} onClick={toggleModal}>
        <div className={'mt-4 absolute right-4'}>
          <span className="text-xl hover:cursor-pointer">&#x2715;</span>
        </div>
      </div>
      <div className="mt-8 mb-0">
        <h3 className="w-10/12 lg:w-9/12 mx-auto text-center mb-1 leading-none">{contentLabel}</h3>
      </div>
      <Container className="pb-4">{children}</Container>
    </Modal>
  )
}
