import * as React from 'react'
import Link from 'next/link'
import { useQueryParam } from '../../../hooks/useQueryParam'
import classNames from 'classnames'
export function LandingPageButton(props: {
  buttonText: string
  buttonProps?: any
  color?: string
  textColor?: string
}) {
  const query = useQueryParam()
  return (
    <Link href={{ pathname: `/get-quote/`, query }}>
      <a>
        <Button
          className={'w-full block sm:w-auto sm:inline -mt-6 sm:mt-0'}
          style={{
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 30,
            paddingRight: 30,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            backgroundColor: props.color || '#00AEEF',
            color: props.textColor || 'white',
          }}
          {...props.buttonProps}
        >
          {props.buttonText}
        </Button>
      </a>
    </Link>
  )
}

export const Button: React.FC<
  { disabled?: boolean } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ children, className, disabled = false, ...rest }) => {
  const disabledClassName = 'disabled:cursor-not-allowed'
  const baseClassName = 'inline-block justify-center text-white py-2 text-lg font-semibold px-5 rounded'
  return (
    <button disabled={disabled} className={classNames(baseClassName, disabledClassName, className)} {...rest}>
      {children}
    </button>
  )
}