import * as React from 'react'
import { useSource } from '../../../hooks/useSource'
import { Wizard } from './Wizard'
import VehicleInfoFormSection, {
  VehicleInfoFormSectionInline,
  VehicleInfoFormSectionInlineStack,
  twVehicleInfoValidationSchema,
} from '../../Forms/ScheduleServiceMultiStep/VehicleInfoFormSection'
import NoEmailPersonalInfoFormSection, {
  NoEmailPersonalInfoFormSectionInline,
  PersonalInfoValidationSchema,
} from '../../Forms/ScheduleServiceMultiStep/NoEmailPersonalInfoFormSection'
import {
  scheduleServiceFormMultiStepSubmit,
  twDefaultInitialWizardFormValues,
  WizardScheduleServiceValues,
} from '../../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormMultiStep'
import { WizardInline } from './WizardInline'

export const HeroForm: React.FC<{
  initialValues?: WizardScheduleServiceValues
  title?: string
  subtitle?: string
}> = ({
  initialValues = twDefaultInitialWizardFormValues,
  title = 'Get A Free Quote Now',
  subtitle = 'Our repair experts will send you a quote in under 5 minutes.',
}) => {
  const source = useSource()


  return (
    <Wizard
      title={title}
      subtitle={subtitle}
      initialValues={{ ...initialValues, source }}
      onSubmit={scheduleServiceFormMultiStepSubmit}
      formPages={[
        {
          element: VehicleInfoFormSection,
          validationSchema: twVehicleInfoValidationSchema,
        },
        {
          element: NoEmailPersonalInfoFormSection,
          validationSchema: PersonalInfoValidationSchema,
        },
      ]}
    />
  )
}

export const HeroFormInline: React.FC<{
  initialValues?: WizardScheduleServiceValues
  linkTarget?: string
  title?: string
  subtitle?: string
  buttonText?: string
  inlineStack?: boolean
  isLinkForm: boolean
}> = ({
  initialValues = twDefaultInitialWizardFormValues,
  isLinkForm,
  linkTarget,
  buttonText,
  inlineStack,
  title,
  subtitle,
}) => {
  const source = useSource()

  return (
    <>
      {title && <h2 className="text-center">{title}</h2>}
      {subtitle && <p className="text-center leading-none mb-3">{subtitle}</p>}
      <WizardInline
        isLinkForm={isLinkForm}
        linkTarget={linkTarget}
        buttonText={buttonText}
        initialValues={{ ...initialValues, source }}
        onSubmit={scheduleServiceFormMultiStepSubmit}
        formPages={[
          {
            element: inlineStack ? VehicleInfoFormSectionInlineStack : VehicleInfoFormSectionInline,
            validationSchema: twVehicleInfoValidationSchema,
          },
          {
            element: NoEmailPersonalInfoFormSectionInline,
            validationSchema: PersonalInfoValidationSchema,
          },
        ]}
      />
    </>
  )
}
