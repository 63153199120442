import * as React from 'react'
import { useSource } from '../../hooks/useSource'
import { generateFakeEmail } from '../../util/form-util'
import { WizardVariation } from '../Builder/components/WizardLinkOut'
import { VehicleInfoFormSectionInline, twVehicleInfoValidationSchema } from './ScheduleServiceMultiStep/VehicleInfoFormSection'

export const twDefaultInitialWizardFormValues = {
  'form-name': 'scheduleServiceWizard',
  fullName: '',
  email: generateFakeEmail(null),
  extraInfo: '',
  vehicleYear: '',
  phoneNumber: '',
  zip: '',
  hearAboutUs: '',
  make: '',
  model: '',
  symptoms: '',
  referredById: '',
  source: '',
  serviceUrgency: '',
  referrerSource: '',
}

export type WizardScheduleServiceValues = {
  fullName: string
  'form-name': string
  email: string
  phoneNumber: string
  vehicleYear: string
  zip: string
  make: string
  model: string
  extraInfo: string
  source: string
  referredById: string
  hearAboutUs: string
  symptoms: string
  serviceUrgency: string
  referrerSource: string
}

export const InlineLinkOutForm: React.FC<{
  initialValues?: WizardScheduleServiceValues
  linkTarget?: string
  subtitle: string
  buttonText?: string
  isLinkForm: boolean
}> = ({ initialValues = twDefaultInitialWizardFormValues, subtitle, linkTarget, buttonText }) => {
  const source = useSource()

  return (
    <WizardVariation
      linkTarget={linkTarget}
      buttonText={buttonText}
      subtitle={subtitle}
      initialValues={{ ...initialValues, source }}
      formPages={[
        {
          element: VehicleInfoFormSectionInline,
          validationSchema: twVehicleInfoValidationSchema,
        },
      ]}
    />
  )
}
