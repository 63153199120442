import * as React from 'react'
import Head from 'next/head'
import * as changeCase from 'capital-case'
import { formatPhoneNumber } from '../../components/utils/string-utils'
import { CityPhoneNumberData } from '../../components/SEMPage/CityPhoneNumberData'
import { useRouter } from 'next/router'
import { faCalendarCheck } from '@fortawesome/pro-duotone-svg-icons/faCalendarCheck'
import { faCarMechanic } from '@fortawesome/pro-duotone-svg-icons/faCarMechanic'
import { HowItWorksGreenCheckSection } from '../../components/SEMPage/HowItWorksGreenCheckSection'
import { ReviewsV2Section } from '../../components/ReviewsV2Section'
import { getTitleFromUTMS } from '../../components/SEMPage/getTitleFromUTMS'
import { NextComponentType } from 'next'
import { propOr } from 'ramda'
import Layout from '../../components/Layout'
import { ServicesHowItWorksSection } from '../../components/SEMPage/ServicesHowItWorksSection'
import { HappinessGuaranteeSection } from '../../components/HappinessGuaranteeSection'
import { CallUsNowBar } from '../../components/CallUsNowBar'
import { twDefaultInitialWizardFormValues } from '../../components/Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormMultiStep'
import HeaderSectionVariation from '../../components/SEMPage/HeaderSectionVariation'
import { faMoneyCheckEditAlt } from '@fortawesome/pro-duotone-svg-icons/faMoneyCheckEditAlt'
import Link from 'next/link'

export type SEMIndexPageTemplateProps = {
  phoneNumber: string
  phoneNumberTxt: string
  formalCity: string
  type?: string
  content?: string
  make: string
  rating: string
  reviewCount: number
}
export const SEMIndexPageTemplate: React.FC<SEMIndexPageTemplateProps> = ({
  phoneNumber,
  formalCity,
  phoneNumberTxt,
  type = 'generic',
  content,
  make,
  rating,
  reviewCount,
}) => {
  const headerTitle = getTitleFromUTMS({
    formalCity,
    phoneNumber,
    phoneNumberTxt,
    type,
    content,
    make,
    rating,
    reviewCount,
  })
  return (
    <Layout
      showFooter={false}
      showGetQuote={false}
      phoneNumber={phoneNumber}
      phoneNumberTxt={phoneNumberTxt}
      title={`Affordable Brake Repair Services${
        formalCity ? ` In ${formalCity}` : ''
      } | NuBrakes Mobile Brake Repair - We Come To You`}
    >
      <Head>
        <meta
          name="description"
          key={'description'}
          content={`Need new brakes? Stay at home and let a certified NuBrakes brake tech come to you and repair your brakes for less than most${
            formalCity ? ` ${formalCity}` : ''
          } brake shops and dealerships on average.`}
        />
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <HeaderSectionVariation
        rating={rating}
        formInitialProps={{
          ...twDefaultInitialWizardFormValues,
          'form-name': 'semScheduleServiceForm',
        }}
        reviewCount={reviewCount}
        title={headerTitle}
      />
      <HowItWorksGreenCheckSection
        title={`Why NuBrakes?`}
        first={{
          title: `We Come To You`,
          subtitle: `Our mobile brake techs deliver top-rated brake repairs at your home or office for up to 40% less than a repair shop.`,
        }}
        second={{
          title: `Better Prices`,
          subtitle: `We're 40% more affordable on average compared to the dealerships and shops so you'll be saving time and money.`,
        }}
        third={{
          title: `Same-Day Availability`,
          subtitle: `Need your brakes repaired today? Same-day service is typically available — contact us to schedule!`,
        }}
      />
      <ServicesHowItWorksSection
        phoneNumber={phoneNumber}
        title={`How NuBrakes Works`}
        description={`Schedule top-rated brake services in minutes.`}
        steps={[
          {
            title: 'Get Your Free Repair Quote',
            description:
              'Let us know what’s going on with your brakes and we’ll send you a free, no obligation repair quote for your vehicle.',
            image: faMoneyCheckEditAlt,
          },
          {
            title: 'Select Your Repair Date',
            description: `Availability as early as today. Choose a repair date and time that works around your schedule.`,
            image: faCalendarCheck,
          },
          {
            title: 'Enjoy Safer, Better Driving',
            description:
              'Our brake technicians will ensure that your brakes are ready to hit the road and get you back to driving worry-free!',
            image: faCarMechanic,
          },
        ]}
        buttonText={`Get A Quote`}
      />
      <ReviewsV2Section
        title={`Over 1000+ 5-Star Reviews`}
        first={{
          review: `We received fantastic, quick service, at a fair price. We got quite a few quotes and NuBrakes was the best price and it turns out, the quickest and best service. Our technician, Mike, was knowledgeable and friendly and got the job done quickly - all at my office. We would definitely recommend them and use the service again.`,
          from: `${formalCity}`,
          reviewer: `Tami F.`,
        }}
        second={{
          from: `${formalCity}`,
          reviewer: `Clinton B.`,
          review: `This service is awesome. You are paying for fast, convenient and good quality work. Communication with Walker was immediate and the technician Micheal was done in half the time that was quoted. I will use NuBrakes again in the future and recommend it to everyone. I mean, they come to you, that was what sold it for me.`,
        }}
        third={{
          review: `Without a doubt would recommend NuBrakes to anyone that needs brakes done. Service tech showed up on time and was professional and quick. And the conveniences having them come to you is hard to beat. Not to mention the price is close to half what you’d pay at a pep boys or any other body shop. Can’t beat it.`,
          from: `${formalCity}`,
          reviewer: `William T.`,
        }}
      />
      <HappinessGuaranteeSection
        title={'Happiness Guaranteed'}
        description={
          <>
            Our brake techs have decades of combined experience, and all services are backed by our{' '}
            <Link href={'/warranty'}>NuBrakes Guarantee</Link>.
          </>
        }
      />
      <CallUsNowBar phoneNumber={phoneNumber} />
    </Layout>
  )
}

export function setContentWithFallbacks(type: string, content: string) {
  if (content !== 'undefined') {
    return content
  }
  switch (type) {
    case 'generic':
      return ''
    case 'comp':
      return 'brake shop'
    case 'vehicle':
      return ''
    case 'vehicle-part':
      return 'brake-parts'
    case 'mm_generic':
      return 'auto-parts'
    case 'mm_vehicle-part':
      return 'auto-parts'
    default:
      return ''
  }
}

const SEMPageVariation: NextComponentType<any, any, { rating: string; reviewCount: number; sessionId: string }> = ({
  rating,
  reviewCount,
}) => {
  const router = useRouter()
  const { query } = router
  const city: string = propOr('', 'city', query)
  const type: string = propOr('', 'type', query)
  const make: string = propOr('', 'make', query)
  let content: string = propOr('', 'utm_content', query)
  content = setContentWithFallbacks(type, content)
  const formalCity = city ? changeCase.capitalCase(city) : ''
  const phoneNumber =
    city && CityPhoneNumberData[city] ? CityPhoneNumberData[city!].phoneNumber : CityPhoneNumberData.default.phoneNumber
  const phoneNumberTxt = formatPhoneNumber(phoneNumber)
  return (
    <SEMIndexPageTemplate
      rating={rating}
      reviewCount={reviewCount}
      phoneNumberTxt={phoneNumberTxt}
      phoneNumber={phoneNumber}
      formalCity={formalCity}
      content={content}
      type={type}
      make={make}
    />
  )
}

export default SEMPageVariation
