import { Builder } from '@builder.io/react'
import { HeroForm, HeroFormInline } from '../components/HeroForm'
import { NoEmailScheduleServiceFormMultiStepLinkOut } from '../components/WizardLinkOut'
import { ContactUsForm } from '../../Forms/ContactUsForm'
import { FleetContactUsForm } from '../../Forms/FleetContactUsForm'
import { BASE_WEB_APP_URL } from './HeroRegistration'

Builder.registerComponent(HeroForm, {
  name: 'Get A Quote Wizard',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Get A Free Quote Now' },
    { name: 'subtitle', type: 'string', defaultValue: 'Our repair experts will send you a quote in under 5 minutes.' },
  ],
})

Builder.registerComponent(HeroFormInline, {
  name: 'Inline Quote Wizard',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Get A Free Quote Now' },
    { name: 'subtitle', type: 'string', defaultValue: 'Our repair experts will send you a quote in under 5 minutes.' },
    { name: 'buttonText', type: 'string', defaultValue: 'Next' },
    { name: 'isLinkForm', type: 'boolean', defaultValue: true },
    { name: 'linkTarget', type: 'string', helperText: 'Customer Web App Pathname', defaultValue: BASE_WEB_APP_URL },
    { name: 'inlineStack', type: 'boolean', defaultValue: true },
  ],
})

Builder.registerComponent(NoEmailScheduleServiceFormMultiStepLinkOut, {
  name: 'Dev CWA Wizard',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Get A Free Quote Now' },
    { name: 'subtitle', type: 'string', defaultValue: 'Our repair experts will send you a quote in under 5 minutes.' },
    { name: 'buttonText', type: 'string', defaultValue: 'Next' },
    { name: 'linkTarget', type: 'string', helperText: 'Customer Web App Pathname', defaultValue: BASE_WEB_APP_URL },
  ],
})

Builder.registerComponent(ContactUsForm, {
  name: 'Contact Us Form',
})

Builder.registerComponent(FleetContactUsForm, {
  name: 'Fleet Contact Us Form',
})
