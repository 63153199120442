import React from 'react'
import { withChildren } from '@builder.io/react'
import Layout from '../../Layout'

const BasePageTemplate = (props) => {
  return (
    <BaseLayoutWithChildren
      description={props.description}
      robots={props.robots}
      showGetQuote={props.showGetQuote}
      showFooter={props.showFooter}
      title={props.title}
      ogImage={props.ogImage}
      phoneNumber={props.phoneNumber}
      footerDisclaimer={props.footerDisclaimer}
    >
      {props.children}
    </BaseLayoutWithChildren>
  )
}
// Exported Here for use in order to allow builder children while keeping the Layout Component in tact for other pages
export const BaseLayoutWithChildren = withChildren(Layout) // Overall HTML BODY & HEAD
export const BasePageTemplateWithChildren = withChildren(BasePageTemplate) // Layout extended for Builder & Children
export default BasePageTemplate
