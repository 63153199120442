import * as React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Container } from '../Container'
import { LandingPageButton } from '../LandingPageButton'

import nubrakesLogoNoText from '../../../public/img/nubrakes-logo-no-text.svg'

type Props = {
  title: string
  cities: any
  phoneNumberTxt?: string
  phoneNumber?: string
  buttonText?: string
}
export const BrakeRepairNearYouWithCities: React.FC<Props> = ({
  title,
  phoneNumberTxt,
  phoneNumber,
  buttonText,
  cities,
}) => {
  return (
    <section className="text-center">
      <Container>
        <div className="pb-4">
          <Image src={nubrakesLogoNoText} alt={'NuBrakes Logo'} className="mx-auto" />
        </div>
        <h2 className="title">{title}</h2>
        {cities && (
          <p className="description">
            We bring the brake shop to you. Find the best{' '}
            <Link href={'/brake-repair-near-me/'}>
              <a>brake repair near you</a>
            </Link>{' '}
            now.
          </p>
        )}
        {phoneNumberTxt && (
          <p className="description">
            Call us at <a href={`tel:${phoneNumber}`}>{phoneNumberTxt}</a> to speak with a brake specialist or click the
            button below to receive a free, no-obligation quote.
          </p>
        )}
        <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-3 text-center sm:text-left">
          {cities.map((city, index) => (
            <div key={index}>
              <Link as={`/cities/${city.slug}/`} href={`/cities/[slug]`}>
                <a>{`${city.cityName} Brake Repair`}</a>
              </Link>
            </div>
          ))}
        </div>
        <div style={{ paddingTop: '5%' }}>
          <LandingPageButton buttonText={buttonText ? buttonText : 'Get A Free Brake Service Estimate'} />
        </div>
      </Container>
    </section>
  )
}
