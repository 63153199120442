import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, Form, Formik } from 'formik'
import { CustomFormControl, Label, PhoneNumberField } from '../../Fields/CustomTextField'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../Button'
import * as Yup from 'yup'
import { nestBackendClient } from '../../clients/nest-backend.client'
import toast from 'react-hot-toast'

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Required')
    .min(7, 'Phone number is not valid')
    .matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      'Phone number is not valid'
    ),
  accept: Yup.boolean().required('you must accept').isTrue('you must accept'),
})

export const AcceptPromotionsForm = ({ checkboxText, termsAndConditionsText, submitText }) => {
  async function onSubmit(values) {
    const formattedPhone = values?.phoneNumber?.replace(/-/g, '')
    if(!values.accept) return
    try {
      await nestBackendClient.post('/contacts/allow-campaign-messages',{
        phone: formattedPhone,
      })
      toast('Thank you, preferences updated!', {
        duration: 4000,
        icon: '✅',
      });
    } catch (error) {
      toast(error.response?.data?.message ?? error.message, {
        icon: '❌',
        duration: 2000,
      });
    }
  }
  return (
    <div className="flex justify-center">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ phoneNumber: '' }}
        enableReinitialize={false}
        isInitialValid={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, handleSubmit }) => (
          <Form className="flex flex-col w-full max-w-lg p-3" onSubmitCapture={handleSubmit}>
            <Field name={'phoneNumber'} component={PhoneNumberField} required={true} />
            <div className="mb-3">
              <Field name={'accept'} componet={CustomFormControl} type="checkbox" id="accept-check" />
              <Label className="ml-2" htmlFor="accept-check">
                {checkboxText}
              </Label>
            </div>
            <div dangerouslySetInnerHTML={{ __html: termsAndConditionsText }}></div>
            <Button className="w-full py-3" disabled={isSubmitting || !isValid} type={'submit'} id={'submit-btn'}>
              {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : submitText || 'Submit'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
