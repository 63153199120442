import * as React from 'react'
import { Container } from '../../../Container'
import { GraySection } from '../GraySection'
import nubrakesLogoNoText from '../../../../../public/img/nubrakes-logo-no-text.svg'
import { LandingPageButton } from '../Button'
import Link from 'next/link'
import Image from 'next/image'

type CityNearYouProps = {
  title: string
  description: string
  phoneNumberTxt?: string
  phoneNumber?: string
  buttonText?: string
  buttonColor?: string
  listOfMinorCities?: string
  builderState?: any
}

export const CityNearYouSection: React.FC<CityNearYouProps> = ({
  title,
  description,
  listOfMinorCities,
  phoneNumberTxt,
  phoneNumber,
  buttonText,
  buttonColor,
  builderState: {
    state: { butterCities },
  },
}) => {
  return (
    <GraySection className="text-center">
      <Container>
        <div className="pb-0">
          <Image src={nubrakesLogoNoText} alt={'NuBrakes Logo'} className="mx-auto" />
        </div>
        <div className={'mb-4 mt-8'}>
          <h3 className="title mt-8">{title}</h3>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {listOfMinorCities ? (
            <p style={{ color: '#818b92' }}>{listOfMinorCities.split(',').join(', ')}</p>
          ) : (
            <BrakeRepairNearYouWithCities cities={butterCities} />
          )}
        </div>
        {phoneNumberTxt && (
          <p className="w-full mb-0 px-8">
            Call us at <a href={`tel:${phoneNumber}`}>{phoneNumberTxt}</a> to speak with a brake specialist or click the
            button below to receive a free, no-obligation quote.
          </p>
        )}

        {buttonText && (
          <div className={'pt-6 mb-8'}>
            <LandingPageButton color={buttonColor} buttonText={buttonText} />
          </div>
        )}
      </Container>
    </GraySection>
  )
}

export const BrakeRepairNearYouWithCities: React.FC<any> = (props) => {
  const { cities } = props
  return (
    <section className="text-center">
      <Container>
        <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-3 text-center sm:text-left">
          {cities &&
            cities.map((city, index) => (
              <div key={index}>
                <Link as={`/cities/${city.slug}/`} href={`/cities/[slug]`}>
                  <a>{`${city.cityName} Brake Repair`}</a>
                </Link>
              </div>
            ))}
        </div>
      </Container>
    </section>
  )
}
