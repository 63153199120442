import * as React from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { CustomTextField } from '../Fields/CustomTextField'
import { useRouter } from 'next/router'
import { catchAll } from '../../util/quote-form-utils'
import { Button } from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import gql from 'graphql-tag'
import { newBackendClient } from '../clients/new-backend.client'
import { Mutation, MutationContactUsArgs } from '../../generated/nest-graphql'

export type ContactUsFormValues = {
  firstName: string
  lastName: string
  email: string
  'form-name': string
  message: string
}

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  message: Yup.string().required('Required'),
})

async function submitToBackend(values: ContactUsFormValues) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { 'form-name': formName, ...rest } = values
  await newBackendClient.mutate<Mutation, MutationContactUsArgs>({
    variables: {
      contactUsInput: rest,
    },
    mutation: gql`
      mutation contactUs($contactUsInput: ContactUsInput!) {
        contactUs(contactUsInput: $contactUsInput)
      }
    `,
  })
}

export const ContactUsForm: React.FC = () => {
  const router = useRouter()
  return (
    <Formik<ContactUsFormValues>
      initialValues={{
        'form-name': 'contact',
        email: '',
        lastName: '',
        firstName: '',
        message: '',
      }}
      validationSchema={formSchema}
      onSubmit={async (values) => {
        await Promise.all([submitToBackend(values), catchAll(values)])
        await router.push('/contact-success/').then(() => window.scrollTo(0, 0))
      }}
    >
      {({ isSubmitting }) => (
        <Form name={'contactForm'}>
          <Field type="hidden" name="form-name" value={'contactForm'} />
          <div className="mb-4">
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <Field name={'firstName'} component={CustomTextField} required={true} />
              </div>
              <div>
                <Field name={'lastName'} component={CustomTextField} required={true} />
              </div>
            </div>
            <div>
              <Field name={'email'} type={'email'} component={CustomTextField} required={true} />
            </div>
            <div>
              <Field name={'message'} as={'textarea'} component={CustomTextField} required={true} />
            </div>
          </div>
          <Button disabled={isSubmitting} type={'submit'}>
            {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : `Submit`}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
