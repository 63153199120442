import React from 'react'
import bgImage from '../../public/img/nubrakes-brake-repair-header.jpeg'
import { BgImage } from './BgImage'

export const AboutPageTitleSection: React.FC<{
  title: string
  description?: string
  bgImageOverride?: string
}> = ({ title, description, bgImageOverride }) => {
  return (
    <section className="py-0">
      <BgImage src={bgImage || bgImageOverride}>
        <div className={'text-center'}>
          <h1 className="title text-white">{title}</h1>
          <p className={`description`}>{description}</p>
        </div>
      </BgImage>
    </section>
  )
}
