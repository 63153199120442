import React from 'react'
import { withChildren } from '@builder.io/react'
import CitiesLayout from '../components/cities/CitiesLayout'

const CityPageTemplate = (props) => {
  return (
    <CityPageLayoutWithChildren
      description={props.description}
      robots={props.robots}
      showGetQuote={props.showGetQuote}
      showFooter={props.showFooter}
      title={props.title}
      ogImage={props.ogImage}
      phoneNumber={props.phoneNumber}
    >
      {props.children}
    </CityPageLayoutWithChildren>
  )
}

export const CityPageLayoutWithChildren = withChildren(CitiesLayout)
export const CityPageLayoutTemplateWithChildren = withChildren(CityPageTemplate)
export default CityPageTemplate
