import * as React from 'react'
import Link from 'next/link'
import * as changeCase from 'capital-case'
import { pipe, take } from 'ramda'
import classNames from 'classnames'
import { mapIndexed } from '../../../util/util'
import { paramCase } from 'param-case'
import { Container } from '../../Container'
import { useQueryParam } from '../../../hooks/useQueryParam'
import Image from 'next/image'

export const CarBrandGrid: React.FC<{ carContent: any[]; title?: string; className?: string; children?: any }> = ({
  carContent,
  title,
  children,
  className,
}) => {
  return (
    <section className={classNames('text-center', className)}>
      <Container>
        <h2 className="title mb-2">{title}</h2>
        {children}
        <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-4">
          {
            pipe(
              take(8),
              mapIndexed((post: any, index) => (
                <CarBrand
                  key={index}
                  logo={post.makeLogo}
                  url={`/cars/${paramCase(post.makeShortName)}-brake-repair/`}
                  makeLongName={post.makeLongName}
                />
              ))
            )(carContent) as any
          }
        </div>
      </Container>
    </section>
  )
}

export const CarBrand: React.FC<{
  logo: any
  url: string
  makeLongName: string
}> = ({ logo, makeLongName, url }) => {
  const query = useQueryParam()
  return (
    <div className="pb-5">
      <Link as={{ pathname: url, query }} href={'/cars/[slug]'}>
        <a>
          <div className="shadow-lg mx-auto p-1 border-2 border-gray-100 rounded" style={{ width: 200, height: 200 }}>
            <Image src={logo} width={200} height={200} alt={`${changeCase.capitalCase(makeLongName)} Logo`} />
          </div>
          <div className="pt-4 font-normal text-lg">{`${changeCase.capitalCase(makeLongName)} Brake Repair`}</div>
        </a>
      </Link>
    </div>
  )
}
